import React, { useContext, useEffect } from 'react'
import { globalHistory } from "@reach/router";
import { Redirect } from '@reach/router'

import { ClientAPIProvider } from '../../services/client';
import UserAPIContext, { UserAPIProvider } from '../../services/user'
import { LocationProvider } from '../../context/location'
import { LocationsAPIProvider } from '../../services/locations';
import { parseId } from '../../utils/path';

import Info from '../../components/info';
import Page from '../../components/page';

const InfoPageInner = ({children}) => {
  let id = parseId(globalHistory.location.pathname);
  // clients are redirected to /client-info after auth.
  const userContext = useContext(UserAPIContext);
  if (!id && userContext.isIdle) {
    userContext.load();
  }

  if (userContext.isResolved && !id && userContext.data.id) {
    return (
      <Redirect to={`/client-info/${userContext.data.id}`} noThrow />
    );
  }

  return (
      <ClientAPIProvider id={id}>
        <div className="page__responsive">
          <Info id={id} />
        </div>
      </ClientAPIProvider>
  )
}

const InfoPage = ({children}) => (
  <Page authenticated={true}>
    <LocationsAPIProvider>
      <LocationProvider>
        <UserAPIProvider>
          <InfoPageInner>
            { children }
          </InfoPageInner>
        </UserAPIProvider>
      </LocationProvider>
    </LocationsAPIProvider>
  </Page>
);

export default InfoPage
