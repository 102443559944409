import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const FieldConditional = (props) => {
  const { condition, children } = props;

  return (
    condition ? <Field {...props}>{ children }</Field> : ''
    );
}

FieldConditional.propTypes = {
  condition: PropTypes.any
}

export default FieldConditional;
