import React, { useState } from 'react'
import Button from '../button'
import Icon from '../icon'

export default function OrderTable({
  allowEdit,
  data = [],
  handleChange,
  handleRemove,
  header,
  id: tableId,
}) {
  // Store to handle input values when editing data
  const [value, setValue] = useState({})

  // If no data setup header
  const noData = [`No ${header.title} added`]

  // Prevent negatives & decimals within the inputs
  const handleKeyDown = event => {
    if (
      event.keyCode === 109 ||
      event.keyCode === 189 ||
      event.keyCode === 110 ||
      event.keyCode === 190
    ) {
      event.preventDefault()
    }
  }

  // Changes were made in the form, store them
  function handleInputChange(prop, event, row, index) {
    let temp = ''
    // If it's not an amount or empty, round to nearest whole number
    if (!prop.includes('amount') && event.target.value !== '') {
      temp = row[prop] = Math.round(event.target.value)
    } else {
      temp = row[prop] = event.target.value
    }
    // Store the curret input into the correct row value
    setValue({ ...value, [prop]: temp })
    //Pass data change back into OrderDetail
    handleChange(row, tableId, index)
    //Clear editing value
    setValue({})
  }

  // Correct currency display
  function formatCurrency(amount) {
    amount = parseFloat(amount, 10)
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  // Create delete button
  const EditButtons = ({ index, tableId, handleRemove }) => {
    return (
      <>
        <Button
          theme="tertiary"
          onClick={() => {
            handleRemove(tableId, index)
          }}
        >
          <Icon icon="close" theme="secondary" /> Remove
        </Button>
      </>
    )
  }

  //OrderTable Return
  return (
    <>
      <h3 className="header--weighted">{header.title}</h3>
      {/* Display if there is a set decription */}
      {header.description && (
        <p className="field-text--label">{header.description}</p>
      )}
      <div className="ReactTable">
        <table className="rt-table">
          <thead className="rt-thead -header">
            <tr className="rt-tr">
              {/* Map headers for table */}
              {header.columns.map((x, index) => (
                <td
                  className={`rt-th rt-header_left ${x.class}`}
                  key={`thc-${index}`}
                >
                  {x.name}
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="rt-tbody">
            {data.length > 0 ? (
              /* Map each row from current table data */
              data.map((currentRow, index) => (
                <tr className="rt-tr" key={index}>
                  {/* Map individual columns within given row */}
                  {header.columns.map((item, k) => (
                    <td className={`rt-td ${item.prop}`} key={`trc-${k}`}>
                      {/* If user has edit permissions, display inputs */}
                      {item.editable && allowEdit ? (
                        <>
                          {item.inputPrefix && <span>{item.inputPrefix}</span>}
                          <input
                            className="field-text--input"
                            data-valid={data[index][item.prop] === "" ? false: true}
                            key={`${tableId}-${index}-${item.prop}`}
                            min="1"
                            name={`${tableId}-${index}-${item.prop}`}
                            onChange={event =>
                              handleInputChange(
                                [item.prop],
                                event,
                                currentRow,
                                index
                              )
                            }
                            onKeyDown={handleKeyDown}
                            required
                            step="1"
                            type="number"
                            value={data[index][item.prop]}
                          />
                        </>
                      ) : item.name === 'Amount' && currentRow[item.prop] !== undefined ? (
                        /* Format display currency to USD */
                        formatCurrency(currentRow[item.prop])
                      ) : (
                        currentRow[item.prop]
                      )}
                      {/* Attach button to item column */}
                      {(k === 0  && allowEdit )? <EditButtons index={index} tableId={tableId} handleRemove={handleRemove} /> : ''}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              /* If no data was in the table display noData*/
              <tr>
                <td>{noData}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
