/**
 *  Service: paymentMethods
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = clientId => `/api/clients/${clientId}/payment-methods`;

export const paymentMethodsLoad = clientId => () => request(route(clientId));

// Create a Context
const PaymentMethodsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data.methods;
  }

  return [];
}

export const PaymentMethodsAPIProvider = ({ children, clientId }) => {
  const loader = useLoads('payment-methods', paymentMethodsLoad, {
    variables: [clientId],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <PaymentMethodsAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </PaymentMethodsAPIContext.Provider>
  )
}

PaymentMethodsAPIProvider.propTypes = {
  children: PropTypes.element,
  clientId: PropTypes.string.isRequired
}

export default PaymentMethodsAPIContext
