import React from 'react'
import { navigate } from 'gatsby'

import Family from '../family'
import ProgressBar from '../../progressBar/info'

import { internal } from '../../../lib/routes'

const ClientInfoFamilyPage = ({id}) => (
  <>
    <ProgressBar id={id} currentStep="3"></ProgressBar>
    <Family id={id} onSuccess={() => navigate(`/client-info/${id}/employment`)} />
  </>
);

export default ClientInfoFamilyPage
