/**
 *  API: job-codes
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const ROUTE_JOB_CODE = '/api/job-codes';
const ROUTE_MAJOR_CODE = '/api/major-codes';
const ROUTE_EXAMINEE_CODE = '/api/examinee-codes';

export const jobCodesLoad = () => request(ROUTE_JOB_CODE);
export const majorCodesLoad = () => request(ROUTE_MAJOR_CODE);
export const examineeCodesLoad = () => request(ROUTE_EXAMINEE_CODE);

// Create a Context
export const JobCodesAPIContext = React.createContext();
export const MajorCodesAPIContext = React.createContext();
export const ExamineeCodesAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  if (!data.codes) {
    return [];
  }
  // Ensure data is an array.
  const values = Array.isArray(data.codes)
    ? data.codes
    : Object.values(data.codes);

    return values;
}

export const JobCodesAPIProvider = ({ children, vocabulary }) => {
  const loader = useLoads('jobCodes', jobCodesLoad, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  const options = getOptionsFromResponse(loader.response);

  return (
    <JobCodesAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options
    })}>
      {children}
    </JobCodesAPIContext.Provider>
  )
}

JobCodesAPIProvider.propTypes = {
  children: PropTypes.element
}

export const MajorCodesAPIProvider = ({ children }) => {
  const loader = useLoads('majorCodes', majorCodesLoad, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  const options = getOptionsFromResponse(loader.response);

  return (
    <MajorCodesAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options
    })}>
      {children}
    </MajorCodesAPIContext.Provider>
  )
}

MajorCodesAPIProvider.propTypes = {
  children: PropTypes.element
}

export const ExamineeCodesAPIProvider = ({ children }) => {
  const loader = useLoads('examineeCodes', examineeCodesLoad, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  const options = getOptionsFromResponse(loader.response);

  return (
    <ExamineeCodesAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options
    })}>
      {children}
    </ExamineeCodesAPIContext.Provider>
  )
}

ExamineeCodesAPIProvider.propTypes = {
  children: PropTypes.element
}