/**
 *  API: products
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';
import { getLabelFromId } from '../utils/objects';

const ROUTE = '/api/products';

export const productsLoad = () => request(ROUTE);

// Create a Context
const ProductsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {
    products: [],
    links: []
  };
}

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  // Ensure data is an array.
  const values = Array.isArray(data.products)
    ? data.products
    : Object.values(data.products);

  return values.map(item => ({
    id: item.sku,
    label: item.title,
    price: item.price
  }));
}

export const ProductsAPIProvider = ({ children }) => {
  const loader = useLoads('products', productsLoad, {
    context: 'products',
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  // Product key / value pairs
  const options = getOptionsFromResponse(loader.response);

  const getLabel = key => getLabelFromId(options, key);

  return (
    <ProductsAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options,
      getProductLabel: getLabel
    })}>
      {children}
    </ProductsAPIContext.Provider>
  );
}

ProductsAPIProvider.propTypes = {
  children: PropTypes.element
}

export default ProductsAPIContext;
