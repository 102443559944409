import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import ProgressStep from './step';
import { internal } from './../../lib/routes';
import useMedia, { breakPoints } from '../../utils/useMedia';

const ProgressBar = ({ currentStep, id }) => {
  const isMobile = useMedia([breakPoints.md], [false], true);

  let steps = [
    {},
    {
      title: "Your Information",
      icon: "client",
      url: `/${internal(id).info.personal}`,
      stepIndex: "1"
    },
    {
      title: "Education",
      icon: "education",
      url: `/${internal(id).info.education}`,
      stepIndex: "2"
    },
    {
      title: "Family",
      icon: "family",
      url: `/${internal(id).info.family}`,
      stepIndex: "3"
    },
    {
      title: "Employment",
      icon: "employment",
      url: `/${internal(id).info.employment}`,
      stepIndex: "4"
    },
    {
      title: "Interests / Hobbies",
      icon: "hobbies",
      url: `/${internal(id).info.interests}`,
      stepIndex: "5"
    },
    {
      title: "Payment",
      icon: "accounting",
      url: `/${internal(id).info.payment}`,
      stepIndex: "6"
    },
    {
      title: "Complete",
      icon: "complete",
      url: null,
      stepIndex: "7"
    }
  ];

  if (isMobile) {
    const parsedStep = Number(currentStep);

    steps = steps.slice(parsedStep - 1, parsedStep + 2);
  }

  return (
    <nav aria-label="Breadcrumb" className="progress-bar">
      <ol className="progress-bar__list">
        {steps.map((step, index) => <Fragment key={index}>
          {step.title &&
          <ProgressStep
            step={currentStep}
            stepIndex={step.stepIndex}
            title={step.title}
            url={step.url}>
            <Icon icon={step.icon}/>
          </ProgressStep>
          }
        </Fragment>)}
      </ol>
    </nav>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.string,
  id: PropTypes.string
};

export default ProgressBar;
