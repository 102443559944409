import React from 'react'
import { navigate } from 'gatsby'

import Personal from '../personal'
import ProgressBar from '../../progressBar/info'

import { internal } from '../../../lib/routes'

const ClientInfoPersonalPage = ({id}) => (
  <>
    <ProgressBar id={id} currentStep="1"></ProgressBar>
    <div className="container-panel">
      <Personal
        heading={'Client Information'}
        description={'Please provide information for the person who will be testing.'}
        id={id}
        onSuccess={() => navigate(`/client-info/${id}/education`)}
        />
    </div>
  </>
)

export default ClientInfoPersonalPage
