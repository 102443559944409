/**
 * A simplification of React Table 6.8.6 to remove the dependency.
 * There were React version dependencies that were preventing us from upgrading
 * and ReactTable had more features than we needed.
 **/
import React, { forwardRef } from 'react'
import {
  flexRender,
} from '@tanstack/react-table'
import ErrorBoundary from './ErrorBoundary'

function asPx (value) {
  value = Number(value)
  return Number.isNaN(value) ? null : `${value}px`
}

const getColumnHeaderClassNames = column => {
  const classNames = ['rt-th', column.columnDef?.meta?.headerProps?.className];

  if (column.getCanSort()) {
    classNames.push('-cursor-pointer select-none');
    const isSorted = column.getIsSorted();
    if (isSorted) {
      classNames.push(`-sort-${isSorted}`);
    }
  }

  return classNames.join(' ');
}

const getColumnCellClassNames = column => {
  const classNames = ['rt-td', column.columnDef?.meta?.cellProps?.className];
  return classNames.join(' ');
}

const getColumnFooterClassNames = column => {
  const classNames = ['rt-td', column.columnDef?.meta?.footerProps?.className];
  return classNames.join(' ');
}

const getColumnStyle = column => {

  const style = {
    'flex': `100 0 auto`,
    'width': asPx(column.getSize()),
    'minWidth': asPx(column.columnDef.minSize),
  }
  if (column.columnDef.maxSize && column.columnDef.maxSize < 10000) {
    style['maxWidth'] = asPx(column.columnDef.maxSize)
  }

  return style;
}

const getColumnHeaderStyle = header => {
  const style = getColumnStyle(header.column);
  style['width'] = asPx(header.getSize());

  return style;
}

/**
 * Determines if any columnDefs in a table have a footer.
 * This allows us to conditionally render the <tfoot> element.
 *
 * @param {Table} table
 *
 * @returns {Boolean}
 *   true if a table has footer cells to render.
 */
const hasFooter = table => {
  return !!table.getAllFlatColumns().find(column => column.columnDef.footer);
}

/**
 * This is a basic (as it can be) React component implementation of a TanStackTable
 * which tries to mimic the style of the original ReactTable v6 component library.
 *
 * See
 *   JIRA issue: https://atendesign.atlassian.net/browse/JOCRF-12
 *   TanStack Table docs: https://tanstack.com/table/v8
 */
const TanStackTable = forwardRef(({table, noDataText}, ref) => {
  return (
    <ErrorBoundary>

    <div className="ReactTable">
      <div role="grid" className="rt-table">
        <div className="rt-thead -header">
          {table.getHeaderGroups().map(headerGroup => (
            <div role="row" key={headerGroup.id} className="rt-tr">
              {headerGroup.headers.map(header => (
                <div
                  role="columnheader"
                  key={header.id}
                  className={getColumnHeaderClassNames(header.column)}
                  style={getColumnHeaderStyle(header)}
                  onClick={header.column.getToggleSortingHandler()}
                  >
                  {header.isPlaceholder
                    ? null
                    : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                      )}
                      </>
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="rt-tbody">
          {table.getRowModel().rows.map(row => (
              <div key={row.index} className="rt-tr-group" role="rowgroup">
                <div key={row.id} className={`rt-tr ${row.index % 2 == 0 ? '-odd' : '-even'}`}>
                  {row.getVisibleCells().map(cell => (
                    <div role="gridcell" key={cell.id}
                      className={getColumnCellClassNames(cell.column)}
                      style={getColumnStyle(cell.column)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  ))}
                </div>
              </div>
            ))
          }
        </div>
        {hasFooter(table) &&
        (<div className="rt-tfoot -footerr">
          {table.getFooterGroups().map(footerGroup => (
            <div role="row" key={footerGroup.id} className="rt-tr">
              {footerGroup.headers.map(header => (
                <div
                  role="gridcell"
                  key={header.id}
                  className={getColumnFooterClassNames(header.column)}
                  style={getColumnStyle(header.column)}>
                  {header.isPlaceholder
                    ? null
                    : (
                      <>
                        {flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                      )}
                      </>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
        )}
        {table.getRowModel().rows.length === 0 && noDataText && (<div className="rt-noData">{noDataText}</div>)}
      </div>
    </div>
    </ErrorBoundary>
    );
  });

  export default TanStackTable
