import React from 'react'

import FieldRadios from './radioGroup'

const FieldMultiRadios = ({ id, label, value, optionGroup, onChange }) => (

  <div className="field-radios">
    <label className="field-group--label" htmlFor={id}>{label}</label>
    <div className="field-grouped field-multi-radios layout--flow-left">
      {optionGroup.map((group, i) => (
        <FieldRadios
          key={i}
          id={id}
          label={group.label}
          value={value}
          layout="tight"
          options={group.options}
          onChange={onChange}
        />
      ))}
    </div>
  </div>
)

export default FieldMultiRadios
