import React from 'react'
import CreditCardInput from 'react-credit-card-input';

const FieldCC = ({label, placeholder = '', values, onChange, onBlur, ...props}) => (
  <div className="field-text">
    <label className="field-text--label">{label}</label>
    <div className="field-text--input">
      <CreditCardInput
        cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
          <input
            {...props}
            onChange={handleCardCVCChange(e => onChange(e))}
            onBlur={onBlur}
            name="card.cvc"
            value={values.cvc}
            required
          />
        )}
        cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
          <input
            {...props}
            onChange={handleCardExpiryChange( e => onChange(e))}
            onBlur={onBlur}
            name="card.expiration"
            value={values.expiration}
            required
          />
        )}
        cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
          <input
            {...props}
            onChange={handleCardNumberChange(e => onChange(e))}
            onBlur={onBlur}
            name="card.number"
            value={values.number}
            required
          />
        )}
      />
    </div>
  </div>
);

export default FieldCC
