import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/family`;

export const clientInfoFamilyLoad = id => () => request(route(id));
export const testingLocationsLoad = () => request('/api/testing-locations');

const ClientInfoFamilyAPIContext = React.createContext();

const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientInfoFamilyAPIProvider = ({ children, id }) => {
  const loader = useLoads('client-info-family', clientInfoFamilyLoad, {
    variables: [id],
    defer: false
  });

  const data = getDataFromResponse(loader.response);

  const testingLocationsLoader = useLoads('testing-locations', testingLocationsLoad, { defer: false });
  const testingLocations = getDataFromResponse(testingLocationsLoader.response).locations;

  return (
    <ClientInfoFamilyAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
      testingLocations
    })}>
      {children}
    </ClientInfoFamilyAPIContext.Provider>
  )
}

ClientInfoFamilyAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ClientInfoFamilyAPIContext
