import React from 'react'
import { navigate } from 'gatsby'
import Education from '../education'
import ProgressBar from '../../progressBar/info'

const ClientInfoEducationPage = ({ id }) => (
  <>
    <ProgressBar id={id} currentStep="2"></ProgressBar>
    <Education
      id={id}
      onSuccess={() => navigate(`/client-info/${id}/family`)}
    />
  </>
)

export default ClientInfoEducationPage
