import React, { useContext, useEffect } from 'react'

import Card from '../../card'
import TabbedCard from '../../tabbedCard'
import ClientContext from '../../../services/client'
import LoadingIndicator from '../../LoadingIndicator'
import OrdersAPIContext, { OrdersAPIProvider } from '../../../services/orders'
import { CouponsAPIProvider } from '../../../services/coupons'
import { AdjustmentsAPIProvider } from '../../../services/adjustments'
import { ProductsAPIProvider } from '../../../services/products'
import TabsContext, { TabsProvider } from '../../../context/tabs'
import Button from '../../button'
import Icon from '../../icon'

const getOrderTabs = orders =>
  orders.map(order => ({
    title: order.id,
    id: order.id,
  }))

const ClientOrdersInner = ({ id, path, allowEdit, children }) => {
  const clientAPI = useContext(ClientContext)
  const ordersAPI = useContext(OrdersAPIContext)
  const { setTabs, setCurrentTab } = useContext(TabsContext)

  const isResolved = clientAPI.isResolved && ordersAPI.isResolved

  useEffect(() => {
    setTabs(getOrderTabs(ordersAPI.data))
  }, [ordersAPI.data])

  if (!isResolved) {
    return (
      <Card modifier="card--page">
        <LoadingIndicator />
      </Card>
    )
  }

  const title = (
    <>
      <h2 className="tabbed-card__heading">Orders</h2>
      {allowEdit && (
        <Button
          theme="primary"
          to={`${path}/add`}
          onClick={() => setCurrentTab('')}
        >
          <Icon icon="plus" theme="primary" />
          <span className="button__label">Add Order</span>
        </Button>
      )}
    </>
  )

  return (
    <Card modifier="">
      <TabbedCard title={title} getTo={orderId => `${path}/${orderId}`}>
        {children}
      </TabbedCard>
    </Card>
  )
}
const ClientOrders = props => (
  <TabsProvider>
    <CouponsAPIProvider>
      <ProductsAPIProvider>
        <AdjustmentsAPIProvider>
          <OrdersAPIProvider clientId={props.id}>
            <ClientOrdersInner {...props} />
          </OrdersAPIProvider>
        </AdjustmentsAPIProvider>
      </ProductsAPIProvider>
    </CouponsAPIProvider>
  </TabsProvider>
)
export default ClientOrders
