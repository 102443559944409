import React, {useContext} from 'react'
import Select from 'react-select';
import { Formik, Form, Field, FieldArray, getIn } from 'formik'

import * as Yup from 'yup';

import FieldCheckbox from '../field/checkbox'
import FieldRadios from '../field/radioGroup'
import FieldText from '../field/formikText'
import FieldPhone from '../field/phone'
import FieldMonth from '../field/month';
import FieldNumber from '../field/number';
import FieldTextArea from '../field/textarea';

import Card from '../card'
import Button from '../button'
import Icon from '../icon'

import { defaults, defaultValue } from '../../lib/defaults'
import { get } from '../../utils/objects'
import ClientAPIContext, {ClientAPIProvider} from '../../services/client'
import ClientUpdateAPIContext, { ClientUpdateAPIProvider } from '../../services/clientUpdate'
import ErrorMessage from '../../components/ErrorMessage'
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import {fields} from '../../utils/contact';
import LoadingIndicator from '../LoadingIndicator'
import { removeAccessToken } from '../../lib/oauth'
import Address from '../field/Address';
import ScrollToFieldError from '../ScrollToFieldError';


const FormSchema = Yup.object().shape({
  address: Yup.object().shape({
    street1: Yup.string()
      .transform(value => value === '' ? null : value)
      .nullable()
      .min(6, 'Address must be longer than 5 characters')
  }),
  email: Yup.string().email('Invalid email').required('Required'),
  contacts: Yup.array().of(Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required')
  })),
  firstname: Yup.string()
    .min(2, 'Too Short!')
    .max(150, 'Too Long!')
    .required('Required'),
  lastname: Yup.string()
    .min(2, 'Too Short!')
    .max(150, 'Too Long!')
    .required('Required'),
   birthMonth: Yup.string()
    .notOneOf(['0'], 'Birth month is required')
    .required('Birth month is required'),
   birthYear: Yup.string()
    .min(4, 'Year must be 4 digits. Ex: 1985')
    .max(4, 'Invalid year')
    .required('Birth year is required'),
  referredEmail: Yup.string()
    .email('Invalid email')
});

const reqsAdditionalFields = () => (
  defaults.referred.filter((value) => (value.collectContactInfo))
)

const requiresContactInfo = (fieldValue) => {
  return reqsAdditionalFields().map(value => value.value).includes(fieldValue)
}

const PersonalInner = ({
  id,
  heading,
  onSuccess = () => {},
  onCancel
}) => {
  const clientAPI = useContext(ClientAPIContext);
  const { isResolved, data } = clientAPI;
  const clientUpdateAPI = useContext(ClientUpdateAPIContext);
  const {messages, setMessages} = useContext(MessagesContext);

  // This is the message that will be displayed to the user
  // to prompt them to provide additional information about
  // the person that referred them.
  const infoPromptMessage = () => (
    <div className='text-highlight'>
    <p>
      <b>That’s great!</b>  We depend on word of mouth for most of our business.  We like to show appreciation to our biggest referrers.  Thank you for helping us do so by providing the following information:
    </p>
    </div>
  )

  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  const client = clientAPI.data;

  return (
    <Card>
      <Formik
        initialValues={{
          firstname: defaultValue(get(client, 'firstName')),
          middlename: defaultValue(get(client, 'middleName')),
          lastname: defaultValue(get(client, 'lastName')),
          contacts: defaultValue(get(client, 'contacts'), []),
          email: defaultValue(client.email),
          phone: defaultValue(client.phone),
          sex: defaultValue(client.sex),
          pronouns: defaultValue(client.pronouns),
          preferred: client.preferred,
          address: defaultValue(client.address),
          birthMonth: defaultValue(client.birthMonth, '0'),
          birthYear: defaultValue(client.birthYear, ''),
          consentNewsletter: defaultValue(client.consentNewsletter),
          testTakerNotes: defaultValue(client.testTakerNotes),
          // Referral fields
          referred: data.referred,
          referredAd: data.referredAd,
          referredName: data.referredName,
          referredEmail: data.referredEmail,
          referredOther: data.referredOther,
          clientNotes: data.clientNotes
          // @end Referral fields
        }}
        enableReinitialize={true}
        validate={values => {}}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          clientUpdateAPI.load(id, Object.assign(
            values,
            // Additionally mark info as complete.
            { infoComplete: 1 }
          ))
            .then(response => {
              // Access token will be revoked by the server on
              // a successful client update.
              removeAccessToken();

              setMessages([{
                type: 'status',
                text: 'Contact information saved.'
              }]);
              setSubmitting(false);
              onSuccess();
            })
            .catch(error => {
              let errors = [];
              console.log(error);
              if (error.response && error.response.errors) {
                errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title))
              } else if (error.status) {
                errors.push(error.status);
              }

              setMessages(errors.map(err => ({
                type: 'error',
                text: err
              })));
              setSubmitting(false)
            })
        }}
      >
        {({ values, submitCount, isValid, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
          <Form>
            <ScrollToFieldError submitCount={submitCount} isValid={isValid} errors={errors} />
            <div className="card--inner">
              <Messages messages={messages} />
              {heading && <h1>{heading}</h1>}
              <div className="layout--pad1-v"><b>Please use this section to enter information for the person who will be taking the test. If you would like to add contact information for a parent or guardian, you can do that by clicking the button below.</b></div>
              <div className="layout--even-3col">
                <Field
                  type="text"
                  name="firstname"
                  >
                    {({ field }) => (
                    <FieldText {...field} label="First Name" />
                  )}
                </Field>
                <Field
                  type="text"
                  name="middlename"
                  >
                    {({ field }) => (
                    <FieldText {...field} label="Middle Name" />
                  )}
                </Field>
                <Field
                  type="text"
                  name="lastname"
                  >
                    {({ field }) => (
                    <FieldText {...field} label="Last Name" />
                  )}
                </Field>
              </div>
              <div className="layout--even-3col">
                <FieldRadios
                  id="sex"
                  value={values.sex}
                  label="Sex"
                  layout="inline"
                  options={defaults.sex.map(defaultSex => ({
                    label: defaultSex.label,
                    value: defaultSex.value
                  }))}
                  onChange={handleChange}
                />
                <Field type="text" name="pronouns">
                  {({ field }) => (
                    <FieldText {...field} label="Preferred Pronouns" />
                  )}
                </Field>
                <div>
                  <div className="layout--even-2col">
                    <div>
                      <FieldMonth onChange={handleChange} value={values.birthMonth} id={'birthMonth'} label={'Birth Month'} className={'select--minimal select--minimal--margin'}/>
                      <ErrorMessage name={"birthMonth"} />
                    </div>
                    <Field type={'number'} name={'birthYear'} id={'birthYear'}>
                      {({ field }) => (
                        <div>
                          <FieldNumber {...field} id={'birthYear'} value={values.birthYear} label="Birth Year" min={1000} max={3000} onChange={handleChange} />
                          <ErrorMessage name={"birthYear"} />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
              <div className="card--inner contact-information">
                <div className="contact-add-info layout--right-2col contact-label">
                  <h2 className="header--weighted">Contact Information</h2>
                </div>
                <div className="contact-add-info layout--even-2col">
                  <div className="field">
                    <Field type="email" name="email">
                      {({ field }) => (
                        <FieldText {...field}
                         onChange={(event) => { setFieldValue(event.target.name, event.target.value.trim()); }}
                         label="Email Address" />
                      )}
                    </Field>
                    <ErrorMessage name={"email"} />
                  </div>
                  <FieldPhone name="phone" label="Phone" />
                  <FieldRadios
                    id={`preferred`}
                    name={`preferred`}
                    value={values.preferred}
                    label="Preferred Contact Method"
                    layout="inline"
                    options={defaults.preferred.map(defaultPref => ({
                      label: defaultPref.label,
                      value: defaultPref.value
                    }))}
                    onChange={handleChange}
                  />
                </div>
                <div className="layout--flow-left layout--pad1-v">
                  <FieldCheckbox
                    checked={values.consentNewsletter}
                    id={`consentNewsletter`}
                    label="Yes, I want to receive your monthly newsletter with tips for using my aptitudes."
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FieldArray name="contacts" render={arrayHelpers => (
                <>
                  {values.contacts.map((contact, index) => (
                    <div key={index} className="card--inner contact-information">
                      <div className="contact-add-info layout--right-2col contact-label">
                        <h2 className="header--weighted">{`Additional Contact ${index + 1}`}</h2>
                        <Button theme="tertiary" onClick={() => arrayHelpers.remove(index)}><Icon icon="close" theme="secondary" /> Remove</Button>
                      </div>
                      <div className="contact-add-info layout--even-2col">
                        <div className="field">
                            <Field type="text" name={`contacts.${index}.name`}>
                              {({field}) => (
                                (<FieldText {...field} label="Name" required />)
                              )}
                            </Field>
                          <ErrorMessage name={`contacts.${index}.name`} />
                        </div>
                        <FieldRadios
                          id={`contacts.${index}.preferred`}
                          name={`contacts.${index}.preferred`}
                          value={values.contacts[index].preferred}
                          label="Preferred Contact Method"
                          layout="inline"
                          options={defaults.preferred.map(defaultPref => ({
                            label: defaultPref.label,
                            value: defaultPref.value
                          }))}
                          onChange={handleChange}
                        />
                        <div className="field">
                          <Field type="email"
                            id={`contacts.${index}.email`}
                            name={`contacts.${index}.email`}
                          >
                            {({field}) => (
                              <FieldText {...field} label="Email Address" required />
                            )}
                          </Field>
                          <ErrorMessage name={`contacts.${index}.email`} />
                        </div>
                        <FieldPhone name={`contacts.${index}.phone`} label="Phone" />

                      </div>
                    </div>
                  ))}

                  {values.contacts.length < 3 && (
                    <div className="card--inner card--divider card--row-alt">
                      <Button theme="secondary" onClick={() => arrayHelpers.push(Object.assign({}, fields))}>
                        <Icon icon="plus" /> Add Contact
                    </Button>
                    </div>
                  )}
                </>
              )}
              />
            <div className="card--inner">
              <h2>Address</h2>
              <Address values={values} setFieldValue={setFieldValue} valuesKey='address'></Address>
            </div>
            <div className="card--inner card--divider">
              <FieldTextArea
                id="testTakerNotes"
                label="Is there anything about the person testing that you'd like us to be aware of?"
                value={values.testTakerNotes}
                onChange={handleChange}
              />
            </div>
            <div className="card--inner card--divider">
              <label className='field-text--label'>How did you hear about JOCRF?</label>
              <Select
                id="referred"
                value={defaults.referred.find(option => {
                  return values.referred && option.value === values.referred
                })}
                name="referred"
                onChange={value => {setFieldValue(`referred`, value.value)}}
                options={defaults.referred.map(referred => ({
                  label: referred.label,
                  value: referred.value
                }))}
              />
            </div>

            <div className={
              ' card--inner-tight card--conditional card--highlight card--conditional--' +
              // Only show if it's one of the people options to add contact info for
              (requiresContactInfo(values.referred) ? 'show' : 'hide')
            }>
              {infoPromptMessage()}
              <div className="layout--even-2col">
                <div>
                  <Field
                    type="text"
                    name="referredName"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Referral name" />
                    )}
                  </Field>
                </div>
                <div>
                  <Field
                    type="email"
                    name="referredEmail"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Referral email" />
                    )}
                  </Field>
                  <ErrorMessage name="referredEmail" />
                </div>
              </div>
            </div>

            {/* Only show if "ad" chosen */}
            <div className={
              'card--inner-tight card--highlight card--conditional card--conditional--' + (values.referred == "ad" ? 'show' : 'hide')}>
              <Field
                type="text"
                name="referredAd"
              >
                {({ field }) => (
                  <FieldText {...field} label="Please specify where you saw an ad" />
                )}
              </Field>
            </div>

            {/* Only show if "other" chosen */}
            {values.referred == "other" &&
              <div className="card--inner-tight">
                <Field
                  type="text"
                  name="referredOther"
                >
                  {({ field }) => (
                    <FieldText {...field} label="Please specify where else" />
                  )}
                </Field>
              </div>
            }


            <div className="card--inner-tight">
              <FieldTextArea
                id="clientNotes"
                label="Are there any further details you’d like to share about how you found us?"
                value={values.clientNotes}
                onChange={handleChange}
              />
            </div>
            <div className="card--inner card--divider layout-add-submit">
              <div className="button-group">
                <Button theme="primary" type="submit" disabled={isSubmitting}>
                  <span className="button__label" >Continue</span>
                  <Icon icon="forward" theme="primary" />
                </Button>
                {onCancel && (
                  <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                    <span className="button__label" >Cancel</span>
                    <Icon icon="close" theme="secondary" />
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

const Personal = (props) => (
  <ClientAPIProvider {...props}>
    <ClientUpdateAPIProvider {...props}>
      <PersonalInner {...props} />
    </ClientUpdateAPIProvider>
  </ClientAPIProvider>
);

export default Personal
