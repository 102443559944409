import * as Yup from 'yup';

export const fields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  preferred: 'email'
}

export const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter a First Name'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter a Last Name'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter an Email')
});
