import React, { useContext } from 'react'
import Card from '../card'
import LoadingIndicator from '../LoadingIndicator'
import OrdersAPIContext, { OrdersAPIProvider } from '../../services/orders'
import { Redirect } from '@reach/router'
import TabsContext from '../../context/tabs'

const OrderDefaultInner = () => {
  const ordersAPI = useContext(OrdersAPIContext)
  const { setCurrentTab } = useContext(TabsContext)
  const isResolved = ordersAPI.isResolved

  // Display loading till we have all the data
  if (!isResolved) {
    return (
      <Card modifier="card--page">
        <LoadingIndicator />
      </Card>
    )
  }
  //Validate if user has a current order
  if (ordersAPI.data.length !== 0 && isResolved) {
    // Set visual tab in lefthand menu to show current order selected
    setCurrentTab(ordersAPI.data[0].id)
    return (
      // Go to first order given
      <Redirect to={`${ordersAPI.data[0].id}`} noThrow />
    )
  } else {
    // Go to add new order if none exist
    return <Redirect to={`add`} noThrow />
  }
}

const OrderDefault = props => {
  return (
    <OrdersAPIProvider clientId={props.id}>
      <OrderDefaultInner {...props} />
    </OrdersAPIProvider>
  )
}

export default OrderDefault
