/* eslint-disable max-statements */
import React, { useContext, useEffect, useState } from 'react'

import { navigate } from 'gatsby'

import Card from '../card'
import PaymentAddForm from './PaymentAddForm'
import FieldRadios from '../field/radioGroup'
import PaymentMethodsAPIContext, { PaymentMethodsAPIProvider } from '../../services/paymentMethods'
import PaymentsAPIContext, { PaymentsAPIProvider } from '../../services/payments'
import { PaymentAddAPIProvider } from '../../services/paymentAdd'
import Button from '../button'
import Icon from '../icon'
import { get } from '../../utils/objects'
import LoadingIndicator from '../LoadingIndicator'

const DEPOSIT_AMOUNT = 100

// Determine the remaining deposit amount
// based on the required deposit, total cost and balance.
const getDeposit = (total, balance, deposit) => Math.max(0, (total - balance - deposit) * -1)

const PaymentAddInner = ({
  clientId,
  orderId,
  onSuccess,
  onFailureNoRetry,
  setOrderMessages,
  showHelpers = true,
  heading = 'Payment Information',
  next,
  allowCash,
  allowCheck,
  allowCustomAmounts,
  allowPayLater
 }) => {
  const paymentMethodsAPI = useContext(PaymentMethodsAPIContext);
  const paymentsAPI = useContext(PaymentsAPIContext);

  const [amount, setAmount] = useState('full');
  const [method, setMethod] = useState(null);
  const [payment, setPayment] = useState({});
  let methods = paymentMethodsAPI.data || [];

  if (allowCheck) {
    methods = [].concat(methods, {
      label: 'Check',
      value: 'check'
    });
  }

  if (allowCash) {
    methods = [].concat(methods, {
      label: 'Cash',
      value: 'cash'
    });
  }

  // Call when paymentMethodsAPI first resolves.
  useEffect(() => {
    // If methods have loaded and none are selected,
    // select the first.
    if (!method && methods.length > 0) {
      setMethod(methods[0].id);
    }
  }, [paymentMethodsAPI.isResolved]);

  // Load payments so we can determine which amounts to show.
  useEffect(() => {
    if (showHelpers) {
      paymentsAPI.load();
    }
  }, [showHelpers]);

  // Handle setting an amount.
  const choose = event => {
    setAmount(event.target.value);
  }

  // Handle navigating to the next page.
  const toNext = () => {
    if (next) {
      navigate(next, {
        state: {
          clientId,
          payment,
        },
      })
    }
  }

  // Determine which amount options we should show.
  const amountOptions = [];
  const amounts = {};
  let paidInFull = false;

  if (showHelpers && paymentsAPI.isResolved) {
    // Show deposit
    const remainingBalance = Math.floor(get(paymentsAPI, 'data.remaining'));
    var totalCost = get(paymentsAPI, 'data.amount');
    // If no payments were made, set total to balance remaining
    if(!totalCost){
      totalCost = remainingBalance;
    }
    const remainingDeposit = getDeposit(totalCost, remainingBalance, DEPOSIT_AMOUNT);
    amounts.deposit = remainingDeposit;
    amounts.full = remainingBalance;

    if (remainingDeposit > 0) {
      amountOptions.push({
        value: 'deposit',
        label: `Pay deposit ($${amounts.deposit})`
      })
    }

    if (remainingBalance > 0) {
      amountOptions.push({
        value: 'full',
        label: `Pay remaining balance ($${amounts.full})`
      })
    }

    paidInFull = remainingBalance <= 0;
  }

  if (allowPayLater) {
    amountOptions.push({
      value: 'none',
      label: `Pay Later (If paying by cash or check)`
    })
  }

  if (!paymentsAPI.isResolved && !paymentsAPI.isRejected) {
    return (
      <div className="container-panel">
        <Card>
          <div className="card--inner">
            <LoadingIndicator />
          </div>
        </Card>
      </div>
    )
  }

  return (
    <div className="container-panel">
     {/* <Card> */}
        {showHelpers && (
          <div className="card--inner">
            <h2>{heading}</h2>
            {paidInFull ? (
              <p>Your account has been paid in full.</p>
            ) : (
              <FieldRadios
                id="amount"
                value={amount}
                label="Full payment is due at the time of the 1st appointment."
                options={amountOptions}
                onChange={choose}
              />
            )}
          </div>
        )}
        {amount === 'none' || paidInFull ? (
          <div className="card--inner card--divider layout-add-submit">
            <Button theme="primary" onClick={toNext}>
              <span className="button__label">Continue</span>
              <Icon icon="forward" theme="primary" />
            </Button>
          </div>
        ) : (
          <PaymentAddForm
            heading={!showHelpers && heading}
            methods={methods}
            method={method}
            clientId={clientId}
            orderId={orderId}
            modal={true}
            onSuccess={() => {
              paymentsAPI.load();
              onSuccess();
            }}
            onFailureNoRetry={onFailureNoRetry}
            setOrderMessages={setOrderMessages}
            payment={payment}
            amounts={amounts}
            amount={amount}
            allowCustomAmounts={allowCustomAmounts}
          />
        )}
      {/* </Card> */}
    </div>
  )
}

const PaymentAdd = props => (
  <PaymentsAPIProvider clientId={props.clientId} orderId={props.orderId}>
    <PaymentMethodsAPIProvider clientId={props.clientId}>
      <PaymentAddAPIProvider>
        <PaymentAddInner {...props} />
      </PaymentAddAPIProvider>
    </PaymentMethodsAPIProvider>
  </PaymentsAPIProvider>
)

export default PaymentAdd
