import React from 'react'
import { navigate } from 'gatsby'
import Employment from '../employment'
import ProgressBar from '../../progressBar/info'

const ClientInfoEmploymentPage = ({ id }) => (
  <>
    <ProgressBar id={id} currentStep="4"></ProgressBar>
    <Employment
      id={id}
      onSuccess={() => navigate(`/client-info/${id}/interests`)}
    />
  </>
)

export default ClientInfoEmploymentPage
