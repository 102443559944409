/**
 *  Service: orders
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';

const route = clientId => `/api/clients/${clientId}/orders`;

export const ordersLoad = clientId => () => request(route(clientId));

// Create a Context
const OrdersAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data.orders;
  }

  return [];
};

export const OrdersAPIProvider = ({ children, clientId }) => {
  const loader = useLoads('orders', ordersLoad, {
    variables: [clientId],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <OrdersAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </OrdersAPIContext.Provider>
  )
};

OrdersAPIProvider.propTypes = {
  children: PropTypes.element,
  clientId: PropTypes.string
};

export default OrdersAPIContext;
