/**
 *  Service: order
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';
import { get } from '../utils/objects';

const route = (clientId, orderId) => `/api/clients/${clientId}/orders/${orderId}`;
const routeTransactions = (clientId, orderId) => `/api/clients/${clientId}/orders/${orderId}/transactions`;

export const orderLoad = (clientId, orderId) => () => request(route(clientId, orderId));
export const orderTransactionsLoad = (clientId, orderId) => () => request(routeTransactions(clientId, orderId));

// Create a Context
const OrderAPIContext = React.createContext();
export const OrderTransactionsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return null;
};

export const OrderTransactionsAPIProvider = ({ children, clientId, orderId }) => {
  const loader = useLoads('orderTransactions', orderTransactionsLoad, {
    variables: [
      clientId,
      orderId
    ],
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <OrderTransactionsAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      orderId,
    })}>
      {children}
    </OrderTransactionsAPIContext.Provider>
  )
};

export const OrderAPIProvider = ({ children, clientId, orderId }) => {
  const loader = useLoads('order', orderLoad, {
    variables: [
      clientId,
      orderId
    ],
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <OrderAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      orderId,
      adjustments: get(data, 'adjustments'),
      products: get(data, 'items'),
      payments: get(data, 'payments'),
      coupons: get(data, 'coupons')
    })}>
      {children}
    </OrderAPIContext.Provider>
  )
};

OrderTransactionsAPIProvider.propTypes = {
  children: PropTypes.element,
  clientId: PropTypes.string,
  orderId: PropTypes.string
};

OrderAPIProvider.propTypes = {
  children: PropTypes.element,
  clientId: PropTypes.string,
  orderId: PropTypes.string
};

export default OrderAPIContext;
