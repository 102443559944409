import React, { useContext, useEffect } from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'

const applyValue = (options, value) => {
  const applyBranchValue = (branch) => {
    return {
      ...branch,
      checked:  Array.isArray(value) ? value.includes(branch.value) : branch.value == value,
      children: branch.children.map(applyBranchValue)
    }
  }
  return options.map(applyBranchValue);
}

const HierarchicalSelect = props => {
  const { update, id, handleChange, handleBlur, options, value, mode } = props;

  const getCurrentValue = (currentNode, selectedNodes) => {
    if (mode == 'radioSelect') {
      return currentNode.checked ? currentNode.value : null;
    }
    return selectedNodes.map((value) => (value.value));
  }

  const onChange = (currentNode, selectedNodes) => {
    var currentValue = getCurrentValue(currentNode, selectedNodes);

    update(id, currentValue);
    handleChange(id, currentValue);
  }

  return (
    <DropdownTreeSelect
      {...props}
      data={applyValue(options, value)}
      onChange={onChange}
      onBlur={handleBlur}
    />
  );
};

HierarchicalSelect.propTypes = {}

HierarchicalSelect.defaultProps = {
  options: []
}

export default HierarchicalSelect;
