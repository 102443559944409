import React from 'react'

import Complete from '../complete'


const ClientInfoCompletePage = ({id}) => (
  <>
    <Complete id={id} />
  </>
);

export default ClientInfoCompletePage
