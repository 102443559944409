import React, { useContext, useEffect, useState } from 'react'

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import DateLabel from '../date/label'
import Currency from '../currency'
import { OrderTransactionsAPIContext } from '../../services/order'
import LoadingIndicator from '../LoadingIndicator'
import TanStackTable from '../TanStackTable'

const columnHelper = createColumnHelper()

const getColumns = data => [
  columnHelper.accessor('date', {
    cell: info => <DateLabel date={info.getValue()} />,
    enableSorting: true,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    },
  }),
  columnHelper.accessor('typeLabel', {
    header: () => 'Type',
    enableSorting: true,
    maxSize: 300,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    },
  }),
  columnHelper.accessor('method', {
    header: () => 'Method',
    enableSorting: true,
    maxSize: 300,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      }
    }
  }),
  columnHelper.accessor('statusLabel', {
    enableSorting: true,
    footer: props => <span>Remaining Balance:</span>,
    header: () => 'Status',
    maxSize: 300,
    meta: {
      cellProps: {
        className: 'rt-td--status',
      },
      headerProps: {
        className: 'rt-header_left',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    }
  }),
  columnHelper.accessor('amount', {
    cell: info => <Currency amount={info.getValue()} />,
    enableSorting: true,
    footer: props => (
      <Currency modifier={'large'} amount={data.remaining || 0} />
    ),
    header: () => 'Amount',
    maxSize: 300,
    meta: {
      cellProps: {
        className: 'rt-td--amount',
      },
      footerProps: {
        className: 'rt-footer--summary',
      }
    },
  })
]

const TransactionsListInner = () => {
  const api = useContext(OrderTransactionsAPIContext)
  const { data, isResolved, orderId, isReloading } = api

  const [sorting, setSorting] = useState([])

  // Similar to componentDidMount
  useEffect(() => {
    api.load();
  }, [orderId])

  const table = useReactTable({
    data: data && data.transactions ? data.transactions : [],
    columns: getColumns(data),
    defaultColumn: {
      size: 100,
      enableSorting: false,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (!isResolved || isReloading) {
    return (
      <LoadingIndicator />
    )
  }

  return (
    <>
      <TanStackTable
        table={table}
        noDataText={'No transactions found'}
      />
    </>
  )
}

const TransactionsList = props => <TransactionsListInner {...props} />

export default TransactionsList
