import React from 'react'

const FieldText = ({ id, label, children, required }) => (
  <div className="field-text">
    <label className="field-text--label" htmlFor={id}>{label} {required && (<span className="field-text__required">Required</span>)}</label>
    <div className="field-text--input">
      { children }
    </div>
  </div>
);

export default FieldText
