import React from 'react'

import Card from '../card'

const Complete = ({id}) => (
  <div className="container-panel">
    <Card>
      <div className="card--inner">
        <h1 className='page--header-title'><strong>Thank You!</strong></h1>
        <p>Thank you for filling out your information sheet. We are looking forward to seeing you. If you need to reschedule your appointments, please let us know as soon as possible. See you soon!</p>
      </div>
    </Card>
  </div>
);

export default Complete
