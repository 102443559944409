import React from 'react'
import { navigate } from 'gatsby'
import Interests from '../interests'
import ProgressBar from '../../progressBar/info'

const ClientInfoInterestsPage = ({ id }) => (
  <>
    <ProgressBar id={id} currentStep="5"></ProgressBar>
    <Interests
      id={id}
      onSuccess={() => navigate(`/client-info/${id}/payment`)}
    />
  </>
)

export default ClientInfoInterestsPage
