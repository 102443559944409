/**
 *  Service: payments
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';

const route = (clientId, orderId) => `/api/clients/${clientId}/orders/${orderId}/payments`;

export const paymentsLoad = (clientId, orderId) => () => request(route(clientId, orderId));

// Create a Context
const PaymentsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return [];
};

export const PaymentsAPIProvider = ({ children, clientId, orderId }) => {
  const loader = useLoads('payments', paymentsLoad, {
    variables: [
      clientId,
      orderId
    ],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <PaymentsAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </PaymentsAPIContext.Provider>
  );
};

PaymentsAPIProvider.propTypes = {
  children: PropTypes.element,
  clientId: PropTypes.string,
  orderId: PropTypes.string
};

export default PaymentsAPIContext;
