import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import MessagesContext from '../../context/messages';

const ProgressStep = ({ step, stepIndex, children, title, url }) => {
  const { clearMessages } = useContext(MessagesContext);
  const content = () => (
    <>
      <div className="button--circle">{children}</div>
      <span className="button__label">{title}</span>
    </>
  );

  return (
    <li className={`progress-bar__item ${step === stepIndex
      ? "current"
      : ''}
        ${step >= stepIndex
      ? 'complete'
      : 'default'}
        ${step === (stepIndex - 1)
      ? 'previous'
      : ''}
        ${step === (stepIndex + 1)
      ? 'next'
      : ''}
      `}>
      {url
        ? (<Link
          to={url}
          onClick={clearMessages}
          className="progress-bar__link" >
          {content()}
          </Link>)
        : content() }
    </li>
  );
};

ProgressStep.propTypes = {
  step: PropTypes.string,
  stepIndex: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string,
  size: PropTypes.string,
  url: PropTypes.string
};

export default ProgressStep;
