import React, { useContext } from 'react'
import Select from 'react-select';
import { Formik, Form, Field, FieldArray } from 'formik'

import FieldRadios from '../field/radioGroup'
import FieldText from '../field/formikText'
import FieldNumber from '../field/number'

import ClientInfoFamilyAPIContext, { ClientInfoFamilyAPIProvider } from '../../services/clientInfoFamily';
import ClientInfoFamilyUpdateAPIContext, { ClientInfoFamilyUpdateAPIProvider } from '../../services/clientInfoFamilyUpdate'
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card'
import Button from '../button'
import Icon from '../icon'
import LoadingIndicator from '../LoadingIndicator'

import { defaultEducation, defaults } from '../../lib/defaults'
import { removeAccessToken } from '../../lib/oauth'
import ScrollToFieldError from '../ScrollToFieldError';

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  </Field>
);


// Only send interests with titles that are set.
const normalizeRequest = values => {
  return {
    ...values,
    familyTested: values.familyTested.filter(familyMember => familyMember.name && familyMember.name.length > 0)
  };
}


const parentFields = {
  occupation: '',
  education: ''
}

const familyTestedFields = {
  name: '',
  city: null,
  year: ''
}

const FamilyInner = ({
                       id,
                       heading = 'Family',
                       onSuccess = () => {},
                       onCancel
                     }) => {
  const api = useContext(ClientInfoFamilyAPIContext);
  const { isResolved, data } = api;
  const clientUpdateAPI = useContext(ClientInfoFamilyUpdateAPIContext);
  const {messages, setMessages} = useContext(MessagesContext);

  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={{
            parents: [data.parents[0], data.parents[1]],
            familyMembersTested: data.familyMembersTested,
            familyTested: data.familyTested.length > 0
              ? data.familyTested
              : [{ ...familyTestedFields }],
            twinOrTriplet: data.twinOrTriplet,
          }}
          enableReinitialize={true}
          validate={values => {}}
          onSubmit={(values, { setSubmitting }) => {
            clientUpdateAPI
              .load(id, normalizeRequest(values))
              .then(response => {
                // Access token will be revoked by the server on
                // a successful client update.
                removeAccessToken();

                setMessages([
                  {
                    type: 'status',
                    text: 'Family information saved.',
                  },
                ])
                setSubmitting(false)
                onSuccess()
              })
              .catch(error => {
                let errors = []
                if (error.response && error.response.errors) {
                  errors = errors.concat(
                    Object.values(error.response.data.errors).map(
                      err => err.title
                    )
                  )
                } else if (error.status) {
                  errors.push(error.status)
                }

                setMessages(
                  errors.map(err => ({
                    type: 'error',
                    text: err,
                  }))
                )
                setSubmitting(false)
              })
            setSubmitting(false)
          }}
        >
          {({
            errors,
            handleChange,
            isSubmitting,
            isValid,
            setFieldValue,
            submitCount,
            values,
          }) => (
            <Form>
              <ScrollToFieldError submitCount={submitCount} isValid={isValid} errors={errors} />
              <div className="card--inner">
                <Messages messages={messages} />
                <h1>{heading}</h1>
              </div>
              <FieldArray
                name="contacts"
                render={arrayHelpers => (
                  <>
                    {values.parents.map((parent, i) => (
                      <div key={i} className="card--inner card--divider">
                        <h2>Parent / Guardian {i + 1}</h2>
                        <Field type="text" name={`parents[${i}].occupation`}>
                          {({ field }) => (
                            <FieldText {...field} label="Occupation" />
                          )}
                        </Field>
                        <FieldRadios
                          id={`parents[${i}].education`}
                          value={values.parents[i].education}
                          label="Education Completed"
                          options={defaultEducation.map(education => ({
                            label: education.label,
                            value: education.value,
                          }))}
                          onChange={handleChange}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
              <div className="card--inner card--divider">
                <FieldRadios
                  id="twinOrTriplet"
                  value={values.twinOrTriplet}
                  label="Are you a twin or triplet?"
                  options={defaults.twinOrTriplet.map(twinOrTriplet => ({
                    label: twinOrTriplet.label,
                    value: twinOrTriplet.value,
                  }))}
                  onChange={handleChange}
                />
              </div>

              <div className="card--inner card--divider">
                <FieldRadios
                  id="familyMembersTested"
                  value={values.familyMembersTested}
                  label="Has any of your family been tested by JOCRF?"
                  options={defaults.confirmationValue.map(
                    confirmationValue => ({
                      label: confirmationValue.label,
                      value: confirmationValue.value,
                    })
                  )}
                  onChange={handleChange}
                />

                {/* Only show is "yes" chosen */}
                {values.familyMembersTested == 1 ? (
                  <FieldArray
                    name="familyTested"
                    render={arrayHelpers => (
                      <>
                        {values.familyTested.map((familyTested, i) => (
                          <div key={i} className="layout--even-3col">
                            <Field
                              type="text"
                              name={`familyTested[${i}].name`}
                            >
                              {({ field }) => (
                                <FieldText {...field} label="Name" />
                              )}
                            </Field>

                            <Field
                              name={`familyTested[${i}].city`}
                              value={values.familyTested[i].city}
                            >
                              {({ field }) => (
                                <div className="">
                                  <label className="field-text--label" htmlFor={`familyTested[${i}]city`}>City</label>
                                  <Select
                                    className="field-select"
                                    type="text"
                                    id={`familyTested[${i}]city`}
                                    options={api.testingLocations}
                                    value={api.testingLocations.find(option => (
                                      option.value === values.familyTested[i].city
                                     ))}
                                    onChange={value => {
                                      setFieldValue(`familyTested[${i}].city`, value.value)
                                    }}
                                  />
                                </div>

                              )}
                            </Field>

                            <FieldNumber
                              id={`familyTested[${i}]year`}
                              label="Year (if known)"
                              value={parseInt(values.familyTested[i].year, 10) || ""}
                              placeholder={new Date().getFullYear()}
                              onChange={handleChange}
                            />
                          </div>
                        ))}

                        <div className="card--inner-tight">
                          <Button
                            theme="secondary"
                            type="button"
                            onClick={() =>
                              arrayHelpers.push(
                                Object.assign({}, familyTestedFields)
                              )
                            }
                          >
                            <Icon icon="plus" /> Add Another Family member
                          </Button>
                        </div>
                      </>
                    )}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Continue</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                      <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                        <span className="button__label" >Cancel</span>
                        <Icon icon="close" theme="secondary" />
                      </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}



const Family = (props) => (
  <ClientInfoFamilyAPIProvider {...props}>
    <ClientInfoFamilyUpdateAPIProvider {...props}>
      <FamilyInner {...props} />
    </ClientInfoFamilyUpdateAPIProvider>
  </ClientInfoFamilyAPIProvider>
);

export default Family
