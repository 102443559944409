/**
 *  API: paymentMethods
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = clientId => `/api/clients/${clientId}/payment-methods`;

export const paymentMethodsSave = (clientId, data) => request(route(clientId), {
  method: 'post',
  data
});

// Create a Context
const PaymentMethodsAddAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const PaymentMethodsAddAPIProvider = ({ children }) => {
  const loader = useLoads('payment-methods-add', paymentMethodsSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <PaymentMethodsAddAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </PaymentMethodsAddAPIContext.Provider>
  )
}

PaymentMethodsAddAPIProvider.propTypes = {
  children: PropTypes.element
}

export default PaymentMethodsAddAPIContext
