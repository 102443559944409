/**
 *  API: coupons
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request';
import { getLabelFromId } from '../utils/objects';

const ROUTE = '/api/coupons';

export const couponsLoad = () => request(ROUTE);

// Create a Context
const CouponsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {
    coupons: [],
    links: []
  };
}

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  // Ensure data is an array.
  if (!Array.isArray(data.coupons)) {
    return [];
  }

  return data.coupons.map(code => ({
    id: code.coupon_id,
    label: code.code
  }));
}

export const CouponsAPIProvider = ({ children }) => {
  const loader = useLoads('coupons', couponsLoad, {
    context: 'coupons',
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  // Promotion key / value pairs
  const options = getOptionsFromResponse(loader.response);

  const getLabel = key => getLabelFromId(options, key);

  return (
    <CouponsAPIContext.Provider value={ Object.assign({}, loader, {
      data,
      options,
      getPromotionLabel: getLabel
    })}>
      {children}
    </CouponsAPIContext.Provider>
  )
}

CouponsAPIProvider.propTypes = {
  children: PropTypes.element
}

export default CouponsAPIContext;
