import React, { useContext, useEffect, getIn } from 'react'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import FieldText from './formikText';
import { Field } from 'formik';
import ErrorMessage from '../ErrorMessage';
import FieldConditional from '../field/conditional'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';


const Address = ({ values: formValues, setFieldValue, showStreet2 = true, valuesKey = null }) => {

    const values = valuesKey ? formValues[valuesKey] : formValues

    const validateZip = value => {
        let error;
        if (!postcodeValidatorExistsForCountry(values.countryCode)) {
            return error;
        }
        if (!postcodeValidator(value, values.countryCode)) {
            error = 'Invalid zip/postal code';
        }
        return error;

    }

    const getName = name => {
        return valuesKey ? `${valuesKey}.${name}` : name 
    }

    return (
        <div>
            <Field
                type="text"
                name={getName('street1')}
                value={values.street1}
            >
                {({ field }) => (<>
                    <FieldText {...field} label="Street Address 1" />
                    <ErrorMessage name={field.name} />
                </>
                )}
            </Field>

            <FieldConditional
                condition={showStreet2}
                type="text"
                name={getName('street2')}
            >
                {({ field }) => (
                    <FieldText {...field} label="Street Address 2" />
                )}
            </FieldConditional>

            <div className="layout--weight-left-3col">
                <Field
                    type="text"
                    name={getName('city')}
                    value={values.city}
                >
                    {({ field }) => (
                        <FieldText {...field} label="City" />
                    )}
                </Field>
                <Field
                    name={getName('state')}
                    value={values.state}
                >
                    {({ field }) => (
                        <div>
                            <label className="field-text--label" htmlFor={field.name}>State/Province</label>
                              <RegionDropdown 
                                  id={field.name}
                                  className="field-select field-select__select"
                                  country={values.countryCode}
                                  valueType="short"
                                  countryValueType="short"
                                  value={values.state}
                                  onChange={value => {
                                      setFieldValue(field.name, value)
                                  }}
                              ></RegionDropdown>
                        </div>
                    )}
                </Field>
                <Field
                    type="text"
                    name={getName('zip')}
                    validate={validateZip}
                >
                    {({ field }) => (
                        <div>
                            <FieldText {...field} label="Zip/Postal code" />
                            <ErrorMessage name={field.name}></ErrorMessage>
                        </div>
                    )}
                </Field>
                <Field
                    name={getName('countryCode')}
                    value={values.countryCode}
                >
                    {({ field }) => (
                        <div>
                            <label className="field-text--label" htmlFor={field.name}>Country</label>
                            <CountryDropdown 
                                id={field.name}
                                className="field-select field-select__select"
                                valueType="short"
                                onChange={value => {
                                    setFieldValue(field.name, value)
                                }}
                                value={values.countryCode}></CountryDropdown>
                        </div>
                    )}
                </Field>
            </div>
        </div>
    );
};

Address.propTypes = {};

Address.defaultProps = {
    values: [],
    options: []
};

export default Address;
