import { Link } from 'gatsby';
import React, { useContext, useState } from 'react'
import TabsContext from '../context/tabs';
import Card from './card';

const TabbedCard = ({ title, children, getTo = () => '' }) => {
  const {
    tabs,
    isCurrentTab,
    setCurrentTab
  } = useContext(TabsContext);

  return (
    <div className="tabbed-card">
      <div className="tabbed-card__controls card--row-alt card--divider-right-inner">
        <div className="card--inner">
          {title}
        </div>
        <ul className="tabbed-card__tabs">
          {tabs.map((tab) => {
            const isCurrent = isCurrentTab(tab.id);
            return (<li className={`tabbed-card__tab ${isCurrent ? 'tabbed-card__tab--active' : ''}`} key={tab.id}>
              {
                isCurrent ?
                  (<span
                    className="tabbed-card__tab-link tabbed-card__tab-link--active">
                    {tab.title}
                  </span>) :
                  (<Link
                    className={`tabbed-card__tab-link ${isCurrent ? 'tabbed-card__tab-link--active' : ''}`}
                    to={getTo(tab.id)}
                    onClick={ () => { if (!isCurrent) { setCurrentTab(tab.id) }} }
                    className="tabbed-card__tab-link tabbed-card__tab-link--inactive">
                    {tab.title}
                  </Link>)
              }

            </li>);
          })}
        </ul>
      </div>
      <div className="tabbed-card__content">
        <Card modifier="card--page card--no-shadow">
          {children}
        </Card>
      </div>
    </div>
  );
}

export default TabbedCard
