import React from 'react'

const SvgIcon = ({ children }) => (
  <svg
    className="field-radio--icon"
    width="17"
    height="13"
    viewBox="0 0 17 13"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
)

const FieldRadio = ({ id, label, value, selected, onChange }) => (
  <div className="field-radio">
    <label className="field-radio--label">
      <input
        type="radio"
        name={id}
        value={value}
        checked={value === selected}
        onChange={onChange}
      />
    <span className="field-radio--input">
        <SvgIcon>
            <g transform="translate(-5.000000, -5.000000)" fill="#4E97E8">
              <circle id="Oval" cx="9" cy="9" r="4"></circle>
            </g>
          </SvgIcon>
          </span>
        <span className="field-radio--text">{label}</span>
    </label>
  </div>
)

export default FieldRadio
