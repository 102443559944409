import React from 'react'
import Select from './select';
import monthArray from '../date/monthArray';


const FieldMonth = ({ id, value, className = 'select--minimal', onChange, label = 'Month', labelClass = 'field-text--label'}) => (
    <div>
      <label htmlFor={id} className={labelClass}>{label}</label>
      <Select name={id} id={id} value={value} nonOption={'Select Month '} options={monthArray} tabIndex={0} onChange={onChange} className={className} />
    </div>
)

export default FieldMonth
