import React from 'react'
import { Field } from 'formik'
import MaskedInput from 'react-text-mask'

import FieldText from './fText'
import { phoneMask } from '../../lib/mask'

const FieldPhone = ({ name, label }) => (
  <FieldText id={name} label={label}>
    <Field type="tel" name={name} render={
      ({ field }) => (
        <MaskedInput mask={phoneMask}
          {...field}
          id={name}
          placeholder="(###) ###-####"
        />
      )}
    />
  </FieldText>
)

export default FieldPhone
