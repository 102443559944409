import React from 'react'

import FieldRadio from './radio'

const layoutClass = layout => {

  if (layout === 'inline') {
    return 'layout--fields-2col'
  }

  if (layout === 'tight') {
    return 'layout--flow-left_tight'
  }

  return ''
}

const FieldRadios = ({ id, label, layout = '', value, options, onChange }) => (
  <div className="field-radios">
    <label className="field-group--label" htmlFor={id}>{label}</label>
    <div className={layoutClass(layout)}>
      {options.map((option, i) => (
        <FieldRadio
          key={i}
          id={id}
          label={option.label}
          value={option.value}
          selected={value}
          onChange={onChange}
        />
      ))}
    </div>
  </div>
);

export default FieldRadios
