export const checkArg = arg => typeof arg !== 'undefined'

/**
 * Given the position and the total, get the percentage
 *
 * @param {number} value
 *   The amount of the total
 * @param {number} total
 *
 * @returns {number}
 *   A percentage as a decimal
 */
export const percent = (value, total) => checkArg(total)
  ? value / total
  : 0

/**
 * Given the percentage and the total, get the position
 *
 * @param {number} percentage
 *   Less than 1
 * @param {number} total
 *
 * @returns {number}
 *   The original amount
 */
export const amount = (percentage, total) => checkArg(total)
  ? percentage * total
  : 0

/**
 * Given a min and a max and step increment, get the number of steps to max.
 *
 * @param {number} min
 * @param {number} max
 * @param {number} step
 *
 * @returns {number}
 *   The number of steps from min to max in increments of "step"
 */
export const steps = (min, max, step) => Math.round((max - min) / step)


export const grid = (total, numSteps) => total / numSteps

// given a percentage, get the current step value.
export const getValue = (percentage, numSteps, min) => Math.round(numSteps * percentage) + min

// given a step value, get the position.
export const getPosition = (value, min, max, numSteps, gridVal) => {
  if (checkArg(numSteps) && checkArg(gridVal)) {
    if (value === min) {
      return 0
    }
    if (value === max) {
      return Math.round(numSteps * gridVal)
    }

    return Math.round((value - min) * gridVal)
  }

  return 0
}
