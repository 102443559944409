import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import useMedia, { breakPoints } from '../../utils/useMedia';
import SvgIcon from './../icon/svgIcon';

import {
  percent,
  steps,
  grid,
  getValue,
  getPosition
} from '../../lib/range';

const FieldNumberRange = ({
  id = '',
  labels = {
    field: '',
    min: '',
    max: ''
  },
  layout = '',
  value = 3,
  min = 1,
  max = 5,
  step = 1,
  onChange = () => ({}),
  update = () => ({})
}) => {

  const [
    focus,
    setFocus
  ] = useState(false);

  const isMobile = useMedia([breakPoints.md], [false], true);

  const rangeWidth = isMobile
    ? 200
    : 240;
  const handleWidth = 18;
  const boundsWidth = rangeWidth + handleWidth;
  const localGrid = grid(rangeWidth, steps(min, max, step));
  const localSteps = steps(min, max, step);
  const tics = [...Array.apply(null, { length: localSteps }).map((tic, i) => i)];

  const onDragStop = (event, data) => {
    const percentComplete = percent(data.lastX, rangeWidth);
    const value = getValue(percentComplete, localSteps, min);

    update(id, value);
  };

  return (
    <div className={`field-text field-range ${layout === 'inline' ? 'layout--weight-left-2col-fixed' : 'layout--fields-centered'}`}>
      <label className="field-text--label" htmlFor={id}>{labels.field}</label>
      <div className="number-slider" style={{ width: `${boundsWidth}px` }}>
        <div className="number-measure" style={{ left: `${handleWidth / 2}px`,
          width: `${rangeWidth}px` }}>
          <div className="number-labels">
            <div className="number-labels--min">{labels.min}</div>
            <div className="number-labels--max">{labels.max}</div>
          </div>
          <div className="number-tics">
            <div className="number-tics--tic"></div>
            {tics.map(tic => <div key={tic} className="number-tics--tic"></div>)}
          </div>
        </div>
        <Draggable
          axis="x"
          bounds="parent"
          onStop={onDragStop}
          grid={[
            localGrid,
            localGrid
          ]}
          position={{ x: getPosition(value, min, max, localSteps, localGrid),
            y: 0 }}
        >
          <div className="number-slider--handle" style={{ width: `${handleWidth}px` }}>
            <SvgIcon
              label="slider-handle"
              width="18"
              height="23"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path fill="#FFFFFF" d="M0.585786438,8.41421356 L9,0 L17.4142136,8.41421356 C17.7892863,8.78928632 18,9.29799415 18,9.82842712 L18,19 C18,20.1045695 17.1045695,21 16,21 L2,21 C0.8954305,21 0,20.1045695 0,19 L0,9.82842712 C0,9.29799415 0.210713681,8.78928632 0.585786438,8.41421356 Z" />
                <path fill="#FFFFFF" stroke={focus ? '#4E97E8' : '#CFD8E8'} d="M9,2.70710678 L0.939339828,10.767767 C0.658035261,11.0490715 0.5,11.4306024 0.5,11.8284271 L0.5,21 C0.5,21.8284271 1.17157288,22.5 2,22.5 L16,22.5 C16.8284271,22.5 17.5,21.8284271 17.5,21 L17.5,11.8284271 C17.5,11.4306024 17.3419647,11.0490715 17.0606602,10.767767 L9,2.70710678 Z" />
                <path fill={focus ? '#4E97E8' : '#8C95AC'} d="M18,21 C18,22.1045695 17.1045695,23 16,23 L2,23 C0.8954305,23 2.91058844e-13,22.1045695 2.91058844e-13,21 L1.70530257e-13,20.1250614 C0.406930041,21.3750205 1.06556189,22 1.97589556,22 C5.19673482,22 9.87419604,22 16.0082792,22 C16.9032913,22 17.5671982,21.3750205 18,20.1250614 L18,21 Z" />
              </g>
            </SvgIcon>
          </div>
        </Draggable>
      </div>

      <div className="field-text--input visually-hidden">
        <input
          type="range"
          id={id}
          name={id}
          min={min}
          max={max}
          step={step}
          value={value || min}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
    </div>
  );
};

FieldNumberRange.propTypes = {
  id: PropTypes.string,
  labels: PropTypes.object,
  layout: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  update: PropTypes.func
}

export default FieldNumberRange;
