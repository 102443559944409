import React, { useContext } from 'react';
import Select from 'react-select';
import { Formik, Form, Field } from 'formik';

import FieldMultiRadios from '../field/radioMultiGroup';
import FieldText from '../field/formikText';
import FieldNumberRange from '../field/numberRange';
import FieldRadioGroup from '../field/radioGroup';

import ClientInfoEducationAPIContext, { ClientInfoEducationAPIProvider } from '../../services/clientInfoEducation';
import ClientInfoEducationUpdateAPIContext, { ClientInfoEducationUpdateAPIProvider } from '../../services/clientInfoEducationUpdate';
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card';
import Button from '../button';
import Icon from '../icon';
import LoadingIndicator from '../LoadingIndicator';

import { defaults, defaultGrades } from '../../lib/defaults';
import { removeAccessToken } from '../../lib/oauth';
import HierarchicalSelect from '../field/hierarchicalSelect';
import FieldConditional from '../field/conditional';
import ScrollToFieldError from '../ScrollToFieldError';

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<div className="field__error">{error}</div>)
        : null
      );
    }}
  </Field>
);

const EducationInner = ({
  id,
  heading = 'Education',
  onSuccess = () => {},
  onCancel,
  showMajorCode = false
}) => {
  const api = useContext(ClientInfoEducationAPIContext);
  const { isResolved, data } = api;
  const clientUpdateAPI = useContext(ClientInfoEducationUpdateAPIContext);
  const { messages, setMessages } = useContext(MessagesContext);
  const yesNoOptions = [
    {'value': 'Yes', 'label': 'Yes'},
    {'value': 'No', 'label': 'No'},
    {'value': 'N/A', 'label': 'Do not wish to answer'}
  ]

  if (!isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  const client = api.data;

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={{
            gradeCompleted: data.gradeCompleted,
            lastSchool: data.lastSchool,
            degree: data.degree,
            fieldOfStudy: data.fieldOfStudy,
            majorCode: data.majorCode,
            likelyToChangeMajor: data.likelyToChangeMajor || '3',
            changeMajorTo: data.changeMajorTo,
            fieldOfStudy2: data.fieldOfStudy2,
            likelyToContinueEdu: data.likelyToContinueEdu || '3',
            speakOtherLangAtHome: data.speakOtherLangAtHome || false,
            languageSpokenAtHome: data.languageSpokenAtHome
          }}
          enableReinitialize={true}
          validate={values => {}}
          onSubmit={(values, { setSubmitting }) => {
            clientUpdateAPI.load(id, values)
              .then(response => {

                /*
                 * Access token will be revoked by the server on
                 * a successful client update.
                 */
                removeAccessToken();

                setMessages([
                  {
                    type: 'status',
                    text: 'Education information saved.'
                  }
                ]);
                setSubmitting(false);
                onSuccess();
              })
              .catch(error => {
                let errors = [];

                if (error.response && error.response.errors) {
                  errors = errors.concat(Object.values(error.response.data.errors).map(err => err.title));
                } else if (error.status) {
                  errors.push(error.status);
                }

                setMessages(errors.map(err => ({
                  type: 'error',
                  text: err
                })));
                setSubmitting(false);
              });
          }}
        >
          {({ values, errors, isValid, submitCount, setFieldValue, setFieldTouched, handleChange, isSubmitting }) => (
            <Form>
              <ScrollToFieldError submitCount={submitCount} isValid={isValid} errors={errors} />
              <div className="card--inner">
                <Messages messages={messages} />
                {heading && <h1>{heading}</h1>}
              </div>
              <div className="card--inner">
                <FieldMultiRadios
                  id="gradeCompleted"
                  label="Last Grade Completed"
                  optionGroup={defaultGrades}
                  value={values.gradeCompleted}
                  onChange={handleChange}
                />
                <div className="layout--weight-left-2col">
                  <Field
                    type="text"
                    name="lastSchool"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Name of Present or Last School" />
                    )}
                  </Field>
                  <Field
                    type="text"
                    name="degree"
                  >
                    {({ field }) => (
                      <FieldText {...field} label="Degree Earned (if any)" />
                    )}
                  </Field>
                </div>
                <Field
                  type="text"
                  name="fieldOfStudy"
                >
                  {({ field }) => (
                    <FieldText {...field} label="Major Field of Study (college or later)" />
                  )}
                </Field>
                <FieldConditional condition={showMajorCode} name="majorCode">
                  {({ field }) => (
                    <div>
                      <label className="field-text--label" htmlFor={field.name}>Major code</label>
                      <HierarchicalSelect
                        options={api.majorCodes}
                        update={setFieldValue}
                        handleChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        id={field.name}
                        mode="radioSelect"
                        {...field}
                      />
                    </div>
                  )}
                </FieldConditional>
                <FieldNumberRange
                  id="likelyToChangeMajor"
                  labels={{
                    field: 'How likely are you to change your major',
                    min: 'Unlikely',
                    max: 'Likely'
                  }}
                  layout="inline"
                  value={Number(values.likelyToChangeMajor)}
                  onChange={handleChange}
                  update={setFieldValue}
                />
                <Field
                  type="text"
                  name="changeMajorTo"
                >
                  {({ field }) => (
                    <FieldText {...field} label="If likely, do you have something in mind?" />
                  )}
                </Field>
                <Field
                  type="text"
                  name="fieldOfStudy2"
                >
                  {({ field }) => (
                    <FieldText {...field} label="Second Major or Minor" />
                  )}
                </Field>
                <FieldNumberRange
                  id="likelyToContinueEdu"
                  labels={{
                    field: 'How likely are you to continue your education?',
                    min: 'Unlikely',
                    max: 'Likely'
                  }}
                  layout="inline"
                  value={Number(values.likelyToContinueEdu)}
                  onChange={handleChange}
                  update={setFieldValue}
                />
                <Field
                  type="checkbox"
                  name="speakOtherLangAtHome"
                  value={values.speakOtherLangAtHome}
                  onChange={handleChange}
                  update={setFieldValue}
                >
                  {({ field }) => (
                    <div className="field-text">
                      <FieldRadioGroup
                        id="speakOtherLangAtHome"
                        options={yesNoOptions}
                        label="Do you speak a language other than English at home?"
                        {...field}
                      ></FieldRadioGroup>
                    </div>
                  )}
                </Field>
                <FieldConditional
                  condition={values.speakOtherLangAtHome=='Yes'}
                  name="languageSpokenAtHome"
                  value={values.languageSpokenAtHome}
                >
                  {({ field }) => (
                    <div className="layout--pad1-v">
                      <label className="field-text--label" htmlFor={field.name}>What language do you speak at home?</label>
                      <Select
                        className="field-select"
                        id={field.name}
                        options={api.clientLanguages}
                        value={api.clientLanguages.find(option => (
                          option.value === values.languageSpokenAtHome
                        ))}
                        onChange={value => {
                          setFieldValue(`languageSpokenAtHome`, value.value)
                        }}
                      ></Select>
                    </div>
                  )}
                </FieldConditional>
              </div>
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Continue</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                    <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                      <span className="button__label" >Cancel</span>
                      <Icon icon="close" theme="secondary" />
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

const Education = props => (
  <ClientInfoEducationAPIProvider {...props}>
    <ClientInfoEducationUpdateAPIProvider {...props}>
      <EducationInner {...props} />
    </ClientInfoEducationUpdateAPIProvider>
  </ClientInfoEducationAPIProvider>
);

export default Education;
