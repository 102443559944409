/**
 *  API: clientInfo
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'

const route = id => `/api/clients/${id}/education`;

export const clientInfoEducationLoad = id => () => request(route(id));
export const majorCodesLoad = () => request('/api/major-codes');
export const clientLanguagesLoad = () => request('/api/client-languages');

// Create a Context
const ClientInfoEducationAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const ClientInfoEducationAPIProvider = ({ children, id, showMajorCode }) => {
  const loader = useLoads('client-info-education', clientInfoEducationLoad, {
    variables: [id],
    defer: false
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  const majorCodesLoader = useLoads('major-codes', majorCodesLoad, {
    defer: showMajorCode ? false : true
  });
  const majorCodes = showMajorCode ? getDataFromResponse(majorCodesLoader.response).codes : {};

  const clientLanguagesLoader = useLoads('client-languages', clientLanguagesLoad, { defer: false });
  const clientLanguages = getDataFromResponse(clientLanguagesLoader.response).languages;


  return (
    <ClientInfoEducationAPIContext.Provider value={ Object.assign({}, loader, {
      id,
      data,
      majorCodes,
      clientLanguages
    })}>
      {children}
    </ClientInfoEducationAPIContext.Provider>
  )
}

ClientInfoEducationAPIProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element
}

export default ClientInfoEducationAPIContext
