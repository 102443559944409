import React, { useContext, useState } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import PaymentMethod from './PaymentMethod'
import FieldText from '../field/formikText'
import Button from '../button'
import Icon from '../icon'
import { paymentSave } from '../../services/paymentAdd'
import Messages from '../messages'
import MessagesContext, { MessagesProvider } from '../../context/messages'

import creditCardType from 'credit-card-type'
import get from 'lodash/get'
import * as Yup from 'yup'
import FieldCC from '../field/creditcard'
import Select from '../field/select'
import { paymentMethodsSave } from '../../services/paymentMethodsAdd'
import FieldCheckbox from '../field/checkbox'
import DialogContext from '../../context/dialog'
import Address from '../field/Address'

const FormSchema = Yup.object().shape({
  amount: Yup.number()
    .positive('Must be a positive amount')
    .typeError('Amount is not a valid number')
    .required('Please enter an amount'),
  payment_method: Yup.string()
    .nullable()
    .required('Please select a payment method'),
})

// Const normalizeRequest = values => values;

const normalizeRequest = values => {
  const date = new Date()

  if (values.payment_method === 'new_card') {
    const ccNumber = values.card.number.split(' ').join('')
    const ccExp = values.card.expiration.split(' / ')

    return {
      date: date.toISOString(),
      save_card: values.save_card && values.countryCode === 'US',
      method: {
        card: {
          type: creditCardType(ccNumber)[0].type,
          number: ccNumber,
          name: `${values.firstname} ${values.lastname}`,
          exp_month: ccExp[0],
          exp_year: `20${ccExp[1]}`, // Lessons from Y2K: This will break in about 80 years.
          cvv: values.card.cvc,
        },
        billing_address: {
          first_name: values.firstname,
          last_name: values.lastname,
          street1: values.street1,
          city: values.city,
          state: values.state,
          postal_code: values.zip,
          country_code: values.countryCode,
        },
      },
      amount: values.amount,
    }
  }

  return {
    date: date.toISOString(),
    method: values.payment_method,
    amount: values.amount,
  }
}

const getDefaultMethod = (method, methods) => {
  // If a method is already set, use it.
  if (method) {
    return method
  }

  // If we have methods to choose from, use the latest.
  if (methods.length > 0) {
    return methods[0].id
  }

  // Default to null.
  return null
}

const PaymentAmountInner = ({
  amount,
  amounts,
  method,
  methods,
  next = () => {},
  onSuccess = () => {},
  onFailureNoRetry,
  onFailureRetry = () => {},
  setOrderMessages = () => {},
  heading,
  clientId,
  orderId,
  modal,
  allowCustomAmounts,
}) => {
  const dialog = useContext(DialogContext)
  const { clearMessages, messages, setMessages } = useContext(MessagesContext)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
  }
  const [newMethod, setNewMethod] = useState(false)

  const saveMethod = values => {
    paymentMethodsSave(clientId, values.method)
      .then(response => {
        if (response.errors) {
          setMessages(
            errors.map(err => ({
              type: 'error',
              text: err,
            }))
          )
          setSubmitting(false)
        } else {
          setMessages([
            ...messages,
            {
              type: 'status',
              text: 'New payment method added!',
            },
          ])
          setTimeout(() => {
            onSuccess()
          }, 1000)
        }
      })
      .catch(error => {
        let errors = []

        console.log(error)
        if (error.response && error.response.errors) {
          errors = errors.concat(
            Object.values(error.response.data.errors).map(err => err.title)
          )
        } else if (error.status) {
          errors.push(error.status)
        }

        setMessages(
          errors.map(err => ({
            type: 'error',
            text: err,
          }))
        )
      })
  }

  return (
    <div className="payment-amount">
      <div>
        <Formik
          initialValues={{
            payment_method: getDefaultMethod(method, methods),
            amount: amounts[amount],
            firstname: '',
            lastname: '',
            street1: '',
            city: '',
            state: '',
            zip: '',
            countryCode: 'US',
            card: { number: '', expiration: '', cvc: '' },
            save_card: true,
          }}
          enableReinitialize={true}
          validate={values => {}}
          validationSchema={FormSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            clearMessages()

            // notes:
            // response is 200+ < 300
            // response.data.errors
            // - card or payment was sent successfully, but, there is a legit decline

            // response 400 or 412
            // error.response.data.errors
            // - validation failed, no payment or order info has been saved

            // response 500+
            // error.response.data.errors
            // - a php exception at various part of the process, payment MIGHT have been saved

            // error.status == "Network Error"
            //

            paymentSave(clientId, orderId, normalizeRequest(values))
              .then(response => {
                // Errors are returned by Drupal, either missing data, 400, or error during save, 206.
                if (response.data.error && response.data.error.length > 0) {
                  setMessages(
                    response.data.errors.map(err => ({
                      type: 'error',
                      text: err,
                    }))
                  )
                  // If there is a long list of payments, scrollToTop doesn't really work?
                  setSubmitting(false)
                } else if (response.errors && response.errors.length > 0) {
                  setMessages(
                    errors.map(err => ({
                      type: 'error',
                      text: err,
                    }))
                  )
                  setSubmitting(false)
                  scrollToTop()
                } else {
                  setMessages([
                    {
                      type: 'status',
                      text:
                        response.data.payments &&
                        response.data.payments[
                          response.data.payments.length - 1
                        ].statusLabel,
                    },
                  ])
                  setSubmitting(false)
                  onSuccess()
                  scrollToTop()
                  resetForm()
                }
              })
              .catch(error => {

                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.errors
                ) {
                  setMessages(
                    Object.values(error.response.data.errors).map(err => ({
                      type: 'error',
                      text: err,
                    }))
                  )
                  setSubmitting(false)
                } else if (error.response && error.response.errors) {
                  errors = errors.concat(
                    Object.values(error.response.errors).map(
                      err => err.title
                    )
                  )
                } else if (error.status) {
                  if (error.status === 'Request failed with status code 503') {
                    setOrderMessages(
                      [{
                        type: 'error',
                        text: 'The site is currently undergoing maintenance.',
                      }])

                  }
                  if (error.status === 'Your session has expired!') {
                   setOrderMessages([{type: 'error', text: error.status}])
                   onFailureNoRetry()
                  }
                  if (error.status === 'Network Error') {
                    setOrderMessages(
                      [{
                        type: 'error',
                        text: 'System error. Please contact an administrator',
                      }])
                  }
                  onFailureNoRetry()
                  scrollToTop()
                }

              })
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <div className="card--inner">
                <Messages messages={messages} />
                {heading && <h2>{heading}</h2>}
                {allowCustomAmounts && (
                  <div className="layout--even-2col">
                    <Field type="text" name="amount" required={true}>
                      {({ field }) => (
                        <FieldText
                          {...field}
                          label="Amount"
                          prefix="dollar"
                          required={true}
                        />
                      )}
                    </Field>
                  </div>
                )}
                <ErrorMessage
                  name="amount"
                  render={msg => <div className="error">{msg}</div>}
                />
                <PaymentMethod
                  clientId={clientId}
                  orderId={orderId}
                  field={{
                    id: 'methods',
                    value: values.payment_method,
                    label: 'Paying with',
                    options: [
                      ...methods.map(method => {
                        if (method.card) {
                          return {
                            label: `Card ending in ${method.card}`,
                            value: method.id,
                          }
                        }

                        return method
                      }),
                      {
                        label: 'New Credit Card',
                        value: 'new_card',
                      },
                    ],
                    onChange: event => {
                      setFieldValue('payment_method', event.target.value)
                      if (event.target.value === 'new_card') {
                        setNewMethod(true)
                      } else {
                        setNewMethod(false)
                      }
                    },
                    required: true,
                  }}
                />
                {newMethod ? (
                  <>
                    <div className="layout--even-2col">
                      <FieldCC
                        values={values.card}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Card Number"
                        required
                      />
                    </div>
                    <div className="layout--even-2col">
                      <Field
                        type="text"
                        name="firstname"
                        render={({ field }) => (
                          <FieldText {...field} label="First Name" required />
                        )}
                      />
                      <Field
                        type="text"
                        name="lastname"
                        render={({ field }) => (
                          <FieldText {...field} label="Last Name" required />
                        )}
                      />
                    </div>
                    <div>
                      <Address setFieldValue={setFieldValue} values={values} showStreet2={false}></Address>
                    </div>
                    {values.countryCode === 'US' ? (
                      <Field
                        name={'save_card'}
                        render={({ field }) => (
                          <>
                            <FieldCheckbox
                              id={'save_card'}
                              label={'Save Card'}
                              checked={values.save_card}
                              tabIndex={0}
                              {...field}
                            />
                          </>
                        )}
                      />
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
                <ErrorMessage
                  name="payment_method"
                  render={msg => <div className="error">{msg}</div>}
                />
              </div>
              <div className="card--inner card__actions-inner card--divider order-payment">
                <Button theme="primary" type="submit" disabled={isSubmitting}>
                  <span className="button__label">
                    {isSubmitting ? 'Please Wait…' : 'Submit Payment'}
                  </span>
                  {isSubmitting ? (
                    <div className="loading__icon loading__icon--small loading__icon--reversed"></div>
                  ) : (
                    <Icon icon="forward" theme="primary" />
                  )}
                </Button>
                {/* Display close form button if it is in a modal */}
                {modal === true ? (
                  <Button
                    theme="secondary"
                    disabled={isSubmitting}
                    type="close"
                    onClick={() => dialog.close()}
                  >
                    Close payment form
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const PaymentAmount = props => (
  <MessagesProvider>
    <PaymentAmountInner {...props} />
  </MessagesProvider>
)

export default PaymentAmount
