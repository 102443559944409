/* eslint-disable camelcase */
import React, { useContext } from 'react'

import Button from '../button'
import Icon from '../icon'

import PaymentMethodsAPIContext from '../../services/paymentMethods';
import { PaymentMethodsAddAPIProvider } from '../../services/paymentMethodsAdd';
import FieldRadios from '../field/radioGroup'
import DialogContext from '../../context/dialog'
import PaymentMethodAddForm from './PaymentMethodAddForm';

const PaymentMethodsInner = ({ clientId, field }) => {
  const paymentMethodsAPI = useContext(PaymentMethodsAPIContext);
  const dialog = useContext(DialogContext);

  const openForm = (e) => {
    dialog.openWith({
      heading: 'Add Payment Method',
      content: <PaymentMethodAddForm
        clientId={clientId}
        onSuccess={() => {
          paymentMethodsAPI.load();
          dialog.close();
        }}
      />,
    });
  }

  return (
    <div className="payment-method">
      <FieldRadios
        {...field}
      />
    </div>
  )
}

const PaymentMethods = props => {
  return (
    <PaymentMethodsAddAPIProvider>
      <PaymentMethodsInner {...props} />
    </PaymentMethodsAddAPIProvider>
  );
}

export default PaymentMethods;
