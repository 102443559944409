/**
 *  Context: tabs
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Create a Context
const TabsContext = React.createContext();

export const TabsProvider = ({ children }) => {
  const [
    tabs,
    setTabs
  ] = useState([]);

  const [
    currentTab,
    setCurrentTab
  ] = useState();

  const isCurrentTab = id => id === currentTab;

  const value = {
    currentTab,
    setCurrentTab,
    isCurrentTab,
    tabs,
    setTabs
  }

  return (
    <TabsContext.Provider value={value}>
      {children}
    </TabsContext.Provider>
  )
}

TabsProvider.propTypes = {
  children: PropTypes.element
}

export default TabsContext;
