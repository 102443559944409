import React, { useContext } from 'react'

import Card from '../../card'
import Button from '../../button'
import Icon from '../../icon'
import ClientAPIContext from '../../../services/client'

const Intro = ({ next, payment }) => {
  const clientApi = useContext(ClientAPIContext)

  const { data, isResolved } = clientApi

  if (!isResolved) {
    return null
  }

  const { name } = data

  return (
    <div className="container-panel">
      <Card>
        <div className="card--inner">
          <h1>Hi, {name}</h1>
          <p>The information we ask for on this form is CONFIDENTIAL. It assists our staff in discussing your results with you, and also helps us in our research.</p>
          <p>It might be helpful for parents and minors to fill this out together, if possible, in order to be accurate about both family background and current interests.</p>
        </div>
        <div className="card--inner card__actions-inner">
          <Button theme="primary" type="submit" onClick={next}>
            <span className="button__label">Go to Information Form</span>
            <Icon icon="forward" theme="primary" />
          </Button>
          <Button theme="primary" type="submit" onClick={payment}>
            <span className="button__label">Go to Payment</span>
            <Icon icon="forward" theme="primary" />
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default Intro
