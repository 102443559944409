// Table Header information to be passed into OrderTable
export default {
  header: {
    adjustments: {
      buttonLabel: 'Add Adjustment',
      title: 'Custom Adjustments',
      columns: [
        {
          class: 'rt-header_left',
          name: 'Item',
          prop: 'label',
        },
        {
          class: 'rt-td--amount',
          name: 'Amount',
          prop: 'amount',
          editable: true,
          inputPrefix: '-$',
        },
      ],
    },
    coupons: {
      checkboxLabel: 'Does this order have a free test or promotion code?',
      buttonLabel: 'Add free test/promotion code',
      title: 'Free test code / promotion codes',
      description: 'If this is a free test or promotion add the corresponding code.',
      columns: [
        {
          class: 'rt-header_left',
          name: 'Item',
          prop: 'code',
        },
      ],
    },
    products: {
      buttonLabel: 'Add Product',
      title: 'Products',
      columns: [
        {
          class: 'rt-header_left',
          name: 'Item',
          prop: 'item',
          editable: false,
        },
        {
          class: 'rt-header_left',
          editable: true,
          name: 'Quantity',
          prop: 'quantity',
        },
        {
          class: 'rt-header_left',
          name: 'SKU',
          editable: false,
          prop: 'sku',
        },
        {
          class: 'rt-td--amount',
          name: 'Amount',
          editable: false,
          prop: 'unit_price',
        },
      ],
    },
  },
}
