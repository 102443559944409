import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../button'
import CouponsAPIContext from '../../services/coupons'
import DialogContext from '../../context/dialog'
import Icon from '../icon'
import LoadingIndicator from '../LoadingIndicator'
import { LocationProvider } from '../../context/location'
import { LocationsAPIProvider } from '../../services/locations'
import Messages from '../messages'
import MessagesContext from '../../context/messages'
import OrderAPIContext, {
  OrderAPIProvider,
  OrderTransactionsAPIProvider,
  OrderTransactionsAPIContext
} from '../../services/order'
import OrderUpdateAPIContext, {
  OrderUpdateAPIProvider,
} from '../../services/orderUpdate'
import OrderTable from './OrderTable'
import { PaymentsAPIProvider } from '../../services/payments'
import PaymentAdd from '../payment/PaymentAdd'
import ProductsAPIContext from '../../services/products'
import TabsContext from '../../context/tabs'
import TransactionsList from './TransactionsList'
import TableHeaders from './TableHeaders'

const ClientOrderDetailInner = ({ orderId, clientId }) => {
  const dialog = useContext(DialogContext)
  const orderAPI = useContext(OrderAPIContext)
  const orderUpdateAPI = useContext(OrderUpdateAPIContext)
  const orderTransactionsAPI = useContext(OrderTransactionsAPIContext)

  const { setCurrentTab } = useContext(TabsContext)
  const { setMessages } = useContext(MessagesContext)

  // Get option data for items to add
  const { getProductLabel, isResolved: productsAPIResolved } = useContext(
    ProductsAPIContext
  )
  const { isResolved: couponsAPIResolved } = useContext(CouponsAPIContext)

  // Hold current state of order
  const [order, setOrder] = useState({
    adjustments: [],
    coupons: [],
    items: [],
  })

  // Destruct order
  const { adjustments, coupons, items } = order

  // Hold Active order data in table
  const [tableData, setTableData] = useState({
    orderAdjustments: [],
    orderCoupons: [],
    orderProducts: [],
  })

  //Validate if all loading has completed
  let isResolved = productsAPIResolved && couponsAPIResolved

  //Add if order exists
  if (orderId) {
    isResolved = isResolved && orderAPI.isResolved
  }

  // Hold previous orderId for new order messages
  const prevOrderIDRef = useRef()

  //Should we display the add payment button
  var orderIsPaid = Math.floor(order.remaining) <= 0 ? true : false
  var orderActiveStatuses = ['in_progress', 'payment_deferred']
  var orderIsActiveStatus = orderActiveStatuses.includes(order.status)
  var displayAddPayment = !orderIsPaid && orderIsActiveStatus && !order.locked

  // Update page data if order number changed
  useEffect(() => {
    if (
      typeof prevOrderIDRef.current != 'undefined' ||
      orderTitle == 'Add New Order'
    ) {
      // Clear messages between orders
      setMessages([])
    }
    // Set orderId
    prevOrderIDRef.current = orderId

    if (orderId) {
      orderAPI.load(clientId, orderId)
    }
  }, [orderId])

  //Update data once orderAPI is reloaded
  useEffect(() => {
    if (orderAPI.data) {
      setOrder(orderAPI.data)
    }
  }, [orderAPI.data])

  // An order was successfully updated
  useEffect(() => {
    if (orderUpdateAPI.isResolved) {
      setMessages([{ text: 'Order has been updated.', type: 'status' }])
      // Reload order after update
      orderAPI.load(clientId, orderId)
      // Reset response & isSubmitting
      orderUpdateAPI.reset()
      setIsSubmitting(false)
    }
  }, [orderUpdateAPI.isResolved])

  // There was an error updating the order
  useEffect(() => {
    if (orderUpdateAPI.isRejected) {
      //If a specific message is sent store it, otherwise default error message
      var setError = orderUpdateAPI.error.response.data.errors[0].message
        ? orderUpdateAPI.error.response.data.errors[0].message
        : 'There was an unexpected error updating the order.'
      // Set error message
      setMessages([{ text: setError, type: 'error' }])
      // Reset response & isSubmitting
      orderUpdateAPI.reset()
      setIsSubmitting(false)
    }
  }, [orderUpdateAPI.isRejected])

  // Set title if new or existing order
  var orderTitle = orderId ? `Order ${orderId}` : 'Add New Order'

  // Update Order Title if canceled, completed, or locked
  switch (order.status) {
    case 'canceled':
    case 'canceled_non_refundable':
      orderTitle = `Order ${orderId} - Canceled`
      break

    case 'completed':
      orderTitle = `Order ${orderId} - Completed`
      break

    default:
      break
  }

  // Set focus to current order
  const orderTab = orderId ? orderId : ''
  setCurrentTab(orderTab)

  // Set loaded order data into table
  useEffect(() => {
    if (items !== undefined && items.length > 0) {
      // Update Product Label based on sku
      // Simplify quantity for display
      let tempProducts = items.map(product => ({
        ...product,
        quantity: parseInt(product.quantity, 10),
        item: getProductLabel(product.sku),
      }))

      // Update Label text for Adjustments
      let tempAdjustments = adjustments.map(adjustment => ({
        ...adjustment,
        label: adjustment.type === 'gift_card' ? 'Gift Card' : '',
      }))

      // Set visual quantity text for Coupons
      let tempCoupons = coupons.map(coupon => ({
        ...coupon,
        quantity: '1',
      }))

      //Set table data to updated values
      setTableData({
        orderAdjustments: tempAdjustments,
        orderCoupons: tempCoupons,
        orderProducts: tempProducts,
      })
    }
  }, [items])

  //Is data done loading?
  if (!isResolved) {
    return <LoadingIndicator />
  }

  // Popup modal for adding a new payment
  function orderPayment() {
    dialog.openWith({
      content: (
        <PaymentAdd
          clientId={clientId}
          orderId={orderId}
          showHelpers={true}
          allowCustomAmounts={true}
          allowCash={false}
          allowCheck={false}
          onSuccess={() => {
            orderTransactionsAPI.load();
            orderAPI.load();
            dialog.close();
            setMessages([{ text: 'Order payments have been updated.', type: 'status' }])
          }}
        />
      ),
    })
  }

  //Main return for OrderDetail
  return (
    <>
      <Messages />
      <h2 className="header--weighted">{orderTitle}</h2>
      {/* Products */}
      <div className="card--inner">
        <OrderTable
          allowEdit={false}
          data={tableData.orderProducts}
          header={TableHeaders.header.products}
          id={'orderProducts'}
        />
      </div>

      {/* Activity */}
      <div className="card--inner">
        <h2>Activity</h2>
        <PaymentsAPIProvider clientId={clientId} orderId={orderId}>
          <>
            <TransactionsList orderId={orderId} />
            {/* Display add payment section */}
            {displayAddPayment ? (
              <Button
                theme="secondary"
                type="submit"
                onClick={() => orderPayment()}
              >
                <Icon icon="plus" />
                <span className="button__label">{'Add New Payment'}</span>
              </Button>
            ) : (
              ''
            )}
          </>
        </PaymentsAPIProvider>
      </div>
    </>
  )
}

const ClientOrderDetail = props => (
  <LocationsAPIProvider>
    <LocationProvider>
      <OrderAPIProvider clientId={props.clientId} orderId={props.orderId}>
        <OrderTransactionsAPIProvider clientId={props.clientId} orderId={props.orderId}>
          <OrderUpdateAPIProvider {...props}>
            <ClientOrderDetailInner {...props} />
          </OrderUpdateAPIProvider>
        </OrderTransactionsAPIProvider>
      </OrderAPIProvider>
    </LocationProvider>
  </LocationsAPIProvider>
)
export default ClientOrderDetail
