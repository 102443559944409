/* eslint-disable max-len */
import React, { useLayoutEffect, useRef } from 'react';

/**
 * Recursive function that loops over an object to get a list
 * of deeply nested properties in dot notation.
 *
 * @param {Object} obj
 *   A plain JS object.
 * @param {String} prefix
 *   The dot notation of parent properties.
 * @param {Array} result
 *   An accumulator.
 *
 * @returns {Array}
 *  A list of properties in dot notation.
 */
const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (!value) {
      return;
    }

    const nextKey = prefix
      ? `${prefix}.${key}`
      : key;

    if (typeof value === "object") {
      transformObjectToDotNotation(value, nextKey, result);
    } else {
      result.push(nextKey);
    }
  });

  return result;
};

// eslint-disable-next-line max-len
export const getFieldErrorNames = formikErrors => transformObjectToDotNotation(formikErrors);

/**
 * Scrolls the browser window to the first error after a submission.
 *
 * @param {Object} props
 *   The passed in props.
 * @param {Number} props.submitCount
 *   The number of times a Formik form has been submitted.
 * @param {Boolean} props.isValid
 *   Whether or not the Formik form is in a valid state.
 * @param {Object} props.erros
 *   The current errors in an invalid Formik form.
 *
 * @returns {void}
 */
const ScrollToFieldError = ({ submitCount, isValid, errors }) => {
  const prevForm = useRef({ errors }).current;

  // eslint-disable-next-line max-statements
  useLayoutEffect(() => {
    if (
      isValid ||
      // Sometimes the submitCount will increase before the form fully updates
      // the error object. This feels a little janky.
      Object.keys(errors).length <= Object.keys(prevForm.errors).length ||
      // Make sure we tried to submit as the errors will validate on change
      // causing the form to scroll and refocus on change.
      submitCount === 0
    ) {
      return;
    }

    const fieldErrorNames = getFieldErrorNames(errors);

    if (fieldErrorNames.length <= 0) {
      return;
    }

    const selectors = fieldErrorNames.map(fieldErrorName => `[name='${fieldErrorName}']:is(select, input)`);
    const element = document.querySelector(selectors.join(', '));

    if (!element) {
      return;
    }

    // Scroll to first known error into view
    element.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
    element.focus({
      preventScroll: true
    });
  }, [submitCount, Object.keys(errors).length]);

  return null;
};

export default ScrollToFieldError;
