import React, { useContext, useEffect } from 'react'
import { Formik, Form, Field, FieldArray, getIn } from 'formik'
import * as Yup from 'yup';

import FieldText from '../field/formikText'
import FieldNumber from '../field/number'
import FieldNumberRange from '../field/numberRange'
import FieldTextArea from '../field/textarea'

import ClientInfoEmploymentAPIContext, { ClientInfoEmploymentAPIProvider } from '../../services/clientInfoEmployment';
import ClientInfoEmploymentUpdateAPIContext, { ClientInfoEmploymentUpdateAPIProvider } from '../../services/clientInfoEmploymentUpdate'
import Messages from '../../components/messages';
import MessagesContext from '../../context/messages';
import Card from '../card'
import Button from '../button'
import Icon from '../icon'
import LoadingIndicator from '../LoadingIndicator'

import { removeAccessToken } from '../../lib/oauth'
import UserAPIContext, { UserAPIProvider } from '../../services/user'
import { LocationsAPIProvider } from '../../services/locations'
import { LocationProvider } from '../../context/location'
import HierarchicalSelect from '../field/hierarchicalSelect'
import { JobCodesAPIContext, JobCodesAPIProvider } from '../../services/taxonomyTerms';
import FieldConditional from '../field/conditional'
import ScrollToFieldError from '../ScrollToFieldError';

let jobFields = {
  title: '',
  years: 0,
  liked: 3,
  code: '',
  description: ''
}

const addJobDefaults = values => {
  Object.keys(values).forEach(key => (
    values[key] = values[key] || jobFields[key]
  ))

  return values;
}

const getCurrentJob = jobs => {
  return jobs.length > 0 ? jobs[0] : jobFields;
}

const getPrevJobs = jobs => {
  const jobsCopy = jobs.slice();
  const prevJobs = jobsCopy.splice(1);

  return prevJobs.length > 0
    ? prevJobs.map(job => (addJobDefaults(job)))
    : [jobFields];
}

// Split values into separate fom fields.
const normalizeResponse = values => {
  return {
    currentJob: getCurrentJob(values),
    prevJobs: getPrevJobs(values),
  }
}

// Combine form fields into a single values array
const normalizeRequest = values => {
  return [
    // Insert
    {
      ...values.currentJob,
    },
    ...values.prevJobs.map(job => ({
      ...job,
    })),
  ]
  // Remove all jobs without titles
  .filter(job => job.title && job.title.length > 0)
}

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);

      return ((touch && error)
        ? (<span className="field__error">{error}</span>)
        : null
      );
    }}
  </Field>
);

const EmploymentInner = ({
   id,
   heading = 'Employment',
   onSuccess = () => {},
   onCancel,
   showJobCode
 }) => {
  const api = useContext(ClientInfoEmploymentAPIContext);
  const { isResolved, data } = api;
  const clientUpdateAPI = useContext(ClientInfoEmploymentUpdateAPIContext);
  const {messages, setMessages} = useContext(MessagesContext);
  const userContext = useContext(UserAPIContext);
  const jobCodesContext = useContext(JobCodesAPIContext);
  const { options } = jobCodesContext;
  const formSchema = Yup.object().shape({
    currentJob: Yup.object().shape({
      title: Yup.string().when(['years'], (years, schema) => {
        return years ? schema.min(1, ' *Required').required(' *Required') : {}
      }
    )})
  })
  // Refetch the client on each page load.
  useEffect(() => {
    // Load if we
    if (jobCodesContext.isIdle) {
      jobCodesContext.load();
    }
    userContext.load();
  }, [])

  if (!isResolved || !userContext.isResolved) {
    return (<Card>
      <div className="card--inner">
        <LoadingIndicator />
      </div>
    </Card>);
  }

  return (
    <div className="container-panel">
      <Card>
        <Formik
          initialValues={normalizeResponse(data)}
          enableReinitialize={true}
          validationSchema={formSchema}
          onSubmit={(values, { setSubmitting }) => {
            clientUpdateAPI
              .load(id, normalizeRequest(values))
              .then(response => {
                // Access token will be revoked by the server on
                // a successful client update.
                removeAccessToken();
                setMessages([
                  {
                    type: 'status',
                    text: 'Employment information saved.',
                  },
                ])
                setSubmitting(false)
                onSuccess()
              })
              .catch(error => {
                let errors = []
                if (error.response && error.response.errors) {
                  errors = errors.concat(
                    Object.values(error.response.data.errors).map(
                      err => err.title
                    )
                  )
                } else if (error.status) {
                  errors.push(error.status)
                }

                setMessages(
                  errors.map(err => ({
                    type: 'error',
                    text: err,
                  }))
                )
                setSubmitting(false)
              })
          }}
        >
          {({
            errors,
            handleChange,
            isSubmitting,
            isValid,
            setFieldValue,
            setFieldTouched,
            submitCount,
            values,
          }) => (
            <Form>
              <ScrollToFieldError submitCount={submitCount} isValid={isValid} errors={errors} />
              <div className="card--inner">
                <Messages messages={messages} />
                <h1>{heading}</h1>
              </div>
              <div className="card--inner card--divider">
                <h2>Current or Most Recent Job</h2>
                <div className="layout--weight-left-3col-fixed">
                  <div>
                    <Field type="text" name="currentJob.title">
                      {({ field }) => (
                        <FieldText {...field}  labelSuffix={(<ErrorMessage name={`currentJob.title`} />)} label="Job Title" />
                      )}
                    </Field>
                    </div>
                    <FieldNumber
                      id="currentJob.years"
                      label="# of Years"
                      value={parseInt(values.currentJob.years) || ""}
                      placeholder={0}
                      onChange={handleChange}
                    />
                  <FieldNumberRange
                    id="currentJob.liked"
                    labels={{
                      field: 'How do you like this job?',
                      min: 'Dislike',
                      max: 'Like',
                    }}
                    value={parseInt(values.currentJob.liked)}
                    onChange={handleChange}
                    update={setFieldValue}
                  />
                </div>
                <div className="layout--flow-left">
                  <FieldConditional condition={showJobCode} name="currentJob.code" onChange={handleChange} value={values.currentJob.code}>
                  {({ field }) => (
                    <div>
                      <label className="field-text--label" htmlFor={field.name}>Job code</label>
                      { jobCodesContext.isResolved  ?
                        <HierarchicalSelect
                          options={options}
                          update={setFieldValue}
                          handleChange={setFieldValue}
                          handleBlur={setFieldTouched}
                          id={field.name}
                          mode="radioSelect"
                          {...field}
                        />
                        :
                        <LoadingIndicator/>
                      }
                    </div>
                  )}
                  </FieldConditional>
                </div>
              </div>

              <div className="card--inner card--divider card--row-alt">
                <Field name="currentJob.description" value={values.currentJob.description}>
                  {({ field }) => (
                    <FieldTextArea {...field}
                      id="currentJob.description"
                      placeholder="Enter text"
                      label="Tell us a bit about what you do"
                      onChange={handleChange}
                    />
                  )}
                </Field>
              </div>
              <div className="card--inner card--divider">
                <h2>Previous Jobs</h2>
              </div>
              <FieldArray
                name="prevJobs"
                render={arrayHelpers => (
                  <div>
                    {values.prevJobs.map((job, i) => (
                      <div
                        key={i}
                        className={`card--inner-tight layout--weight-left-3col-fixed ${
                          i % 2 === 0 ? 'card--row' : 'card--row-alt'
                        }`}
                      >
                        <Field type="text" name={`prevJobs[${i}].title`}>
                          {({ field }) => (
                            <FieldText {...field} label="Job Title" labelSuffix={(<ErrorMessage name={`prevJobs[${i}].title`} />)} />
                          )}
                        </Field>
                        <FieldNumber
                          id={`prevJobs[${i}].years`}
                          label="# of Years"
                          value={values.prevJobs[i].years}
                          placeholder={0}
                          onChange={handleChange}
                        />
                        <FieldNumberRange
                          id={`prevJobs[${i}].liked`}
                          labels={{
                            field: 'How did you like this job?',
                            min: 'Dislike',
                            max: 'Like',
                          }}
                          value={parseInt(values.prevJobs[i].liked)}
                          onChange={handleChange}
                          update={setFieldValue}
                        />
                      </div>
                    ))}
                    <div className="card--inner-tight">
                      <Button
                        theme="secondary"
                        type="button"
                        onClick={() =>
                          arrayHelpers.push(Object.assign({}, jobFields))
                        }
                      >
                        <Icon icon="plus" /> Add Another Job
                      </Button>
                    </div>
                  </div>
                )}
              />
              <div className="card--inner card--divider layout-add-submit">
                <div className="button-group">
                  <Button theme="primary" type="submit" disabled={isSubmitting}>
                    <span className="button__label">Continue</span>
                    <Icon icon="forward" theme="primary" />
                  </Button>
                  {onCancel && (
                      <Button theme="secondary" type="button" disabled={isSubmitting} onClick={onCancel}>
                        <span className="button__label" >Cancel</span>
                        <Icon icon="close" theme="secondary" />
                      </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

const Employment = (props) => (
  <ClientInfoEmploymentAPIProvider {...props}>
    <ClientInfoEmploymentUpdateAPIProvider {...props}>
      <LocationsAPIProvider {...props}>
        <LocationProvider {...props}>
          <UserAPIProvider {...props}>
            <JobCodesAPIProvider {...props}>
              <EmploymentInner {...props} />
            </JobCodesAPIProvider>
          </UserAPIProvider>
        </LocationProvider>
      </LocationsAPIProvider>
    </ClientInfoEmploymentUpdateAPIProvider>

  </ClientInfoEmploymentAPIProvider>
);

export default Employment
