/**
 *  API: payment
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLoads } from 'react-loads';
import { request } from '../lib/request'
import { removeAccessToken } from '../lib/oauth';

const route = (clientId, orderId) => `/api/clients/${clientId}/orders/${orderId}/payments`;

export const paymentSave = (clientId, orderId, data) => request(route(clientId, orderId), {
  method: 'post',
  data
}).finally(() => { removeAccessToken(); })

// Create a Context
const PaymentAddAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {};
}

export const PaymentAddAPIProvider = ({ children }) => {
  const loader = useLoads('payment-add', paymentSave, {
    defer: true
  });

  // Raw response data
  const data = getDataFromResponse(loader.response);

  return (
    <PaymentAddAPIContext.Provider value={ Object.assign({}, loader, {
      data
    })}>
      {children}
    </PaymentAddAPIContext.Provider>
  )
}

PaymentAddAPIProvider.propTypes = {
  children: PropTypes.element
}

export default PaymentAddAPIContext
